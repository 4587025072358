import { React, useState, useEffect } from "react";
import GenteContacto from "../../images/contacto/contacto-gente.png";
import ChicaOk from "../../images/contacto/chica-ok.png";
import "../../index.css";
import {
  GetParamsFormularioContacto,
  AddClienteFormularioContacto,
} from "../../services/actions/ContactoAction";
import Toastify from "toastify-js";
import { Modal } from "bootstrap";
import { useNavigate } from "react-router";

function PrincipalContacto({ isDay }) {
  const history = useNavigate();
  const [validado, setValidado] = useState(false);
  const [values, setValues] = useState({
    nombreCompleto: "",
    rut: "",
    cargo: "",
    email: "",
    numeroCelular: "",
    nombreEmpresa: "",
    webEmpresa: "",
    rubro: "",
    idTipoConsulta: "",
    razonSocial: "",
    consulta: "",
  });
  //document.body.scrollTop = document.documentElement.scrollTop = 0;

  const [tiposConsulta, setTiposConsulta] = useState([]);
  const [tieneEmpresa, setTieneEmpresa] = useState("no");
  const onOptionChange = (e) => {
    setTieneEmpresa(e.target.value);
  };

  function handleChange(evt) {
    /*
        evt.target es el elemento que ejecuto el evento
        name identifica el input y value describe el valor actual
        */
    const { target } = evt;
    const { name, value } = target;

    const newValues = {
      ...values,
      [name]: value,
    };

    // Sincroniza el estado de nuevo
    setValues(newValues);
  }

  function limpiarCamposContacto() {
    const newValues = {
      ...values,
      ["nombreCompleto"]: "",
      ["rut"]: "",
      ["cargo"]: "",
      ["email"]: "",
      ["numeroCelular"]: "",
      ["nombreEmpresa"]: "",
      ["webEmpresa"]: "",
      ["rubro"]: "",
      ["idTipoConsulta"]: 0,
      ["razonSocial"]: "",
      ["consulta"]: "",
    };

    // Sincroniza el estado de nuevo
    setValues(newValues);
    setValidado(true);
  }

  function validar() {
    let infoForm = {
      nombreCompleto: values["nombreCompleto"],
      email: values["email"],
      numeroCelular: values["numeroCelular"],
      tieneEmpresa: tieneEmpresa === "no" ? false : true,
      nombreEmpresa: values["nombreEmpresa"],
      webEmpresa: values["webEmpresa"],
      idTipoConsulta: values["idTipoConsulta"],
      consulta: values["consulta"],
    };

    if (
      infoForm.nombreCompleto.length === 0 ||
      infoForm.email.length === 0 ||
      infoForm.numeroCelular.length === 0 ||
      infoForm.consulta.length === 0 ||
      infoForm.idTipoConsulta === ""
    ) {
      console.log("validacion no conseguida");
      Toastify({
        text: "(*) Debes llenar los campos obligatorios del formulario 📄📄",
        className: "info",
        duration: 5000,
        style: {
          background: "linear-gradient(to right, #ff5099, #ff0f4f)",
          borderRadius: "0px 0px 15px 15px",
        },
      }).showToast();

      setValidado(false);
      return false;
    }

    console.log("validacion correcta");
    setValidado(true);
    return true;
  }

  function handleSubmit(evt) {
    evt.preventDefault();

    // Loop over them and prevent submission
    const form = evt.currentTarget;
    form.classList.add("was-validated");

    form.addEventListener(
      "submit",
      (event) => {
        if (!form.checkValidity()) {
          event.preventDefault();
          event.stopPropagation();
        }
      },
      false
    );

    if (validado) {
      console.log("validacion lograda");

      // mostrar modal
      const myModal = new Modal("#example-modal", null);
      const modalToggle = document.getElementById("example-modal");
      myModal.show(modalToggle);

      let cliente = {
        nombreCompleto: values["nombreCompleto"],
        cargo: values["cargo"],
        rut: values["rut"],
        email: values["email"],
        numCelular: values["numeroCelular"],
        consulta: values["consulta"],
        idConsutlaTipo: parseInt(values["idTipoConsulta"]),
        nombreEmpresa: values["nombreEmpresa"],
        razonSocialEmpresa: values["razonSocial"],
        rutEmpresa: "",
        rubro: values["rubro"],
        sitioWeb: values["webEmpresa"],
      };

      console.log("enviando datos ");

      AddClienteFormularioContacto(cliente)
        .then((response) => {
          console.log(response.data);
        })
        .catch((error) => {
          console.log(error);
        });

      setValidado(false);
      limpiarCamposContacto();
    } else {
      console.log("validacion invalida");
    }
  }

  function cargarParametrosContacto() {
    GetParamsFormularioContacto()
      .then((response) => {
        setTiposConsulta(response.data.consultaTipos);
      })
      .catch((error) => {
        console.log(error);
      });
  }
  
  useEffect(() => {
    window.scrollTo(0, 0); // Esto hará que el scroll vaya al inicio cada vez que se cargue el componente
    const textElements = document.querySelectorAll(".scroll-text");

    const showOnScroll = () => {
      const triggerBottom = window.innerHeight * 0.9; // 90% del viewport

      textElements.forEach((element) => {
        const elementTop = element.getBoundingClientRect().top;

        if (elementTop < triggerBottom) {
          element.classList.add("show");
        } else {
          element.classList.remove("show");
        }
      });
    };

    window.addEventListener("scroll", showOnScroll);
    showOnScroll(); // Ejecuta la función al cargar para mostrar imágenes visibles al inicio

    cargarParametrosContacto();
  }, []);

  return (
    <div
      className={
        isDay === "dia"
          ? "container col-xxl-8 px-4 py-5"
          : "container col-xxl-8 px-4 py-5 text-white"
      }
    >
      <div className="row flex-lg-row-reverse align-items-center g-5 py-5">
        <title>Contáctate con Amática</title>
        <div className="col-12 col-lg-6 mx-auto scroll-text from-right">
          <img
            onContextMenu={(e) => e.preventDefault()}
            src={GenteContacto}
            className="img-fluid"
            alt=""
          />
        </div>

        <div className="col-lg-6 scroll-text from-left">
          <h1 className="display-5 fw-bold lh-1 mb-3">
            Pongámonos en contacto,
          </h1>
          <h1 className="display-6 fw-bold lh-1 mb-3">
            nos comunicaremos contigo para resolver consultas
          </h1>
          <p className="lead">
            Solo debes llenar el formulario de abajo y se te contactará pronto,
            ya sea por dudas, para pedir cotizaciones o para sugerencias,
            recuerda que aquí estamos.
          </p>
        </div>

        <div className="row col-md-8 col-12 mx-auto mt-5 scroll-text from-bottom">
          <h3 className="mb-3">Formulario de contacto</h3>

          <form onSubmit={handleSubmit} className="needs-validation" noValidate>
            <div className="row g-3 tab-content text-start">
              <div className="col-sm-6">
                <label
                  htmlFor="nombreCompleto"
                  className="form-label campo-requerido"
                >
                  Nombre y apellido
                </label>
                <div className="input-group">
                  <span
                    className={
                      isDay === "dia"
                        ? "input-group-text"
                        : "input-group-text bg-black"
                    }
                    id="inputGroupPrepend"
                  >
                    <i
                      className={
                        isDay === "dia"
                          ? "bi bi-fonts"
                          : "bi bi-fonts text-white"
                      }
                    ></i>
                  </span>
                  <input
                    value={values.nombreCompleto}
                    onChange={handleChange}
                    type="text"
                    className={
                      isDay === "dia"
                        ? "form-control rounded-end"
                        : "form-control rounded-end bg-dark text-white"
                    }
                    aria-describedby="inputGroupPrepend"
                    name="nombreCompleto"
                    required
                  />
                  <div className="invalid-feedback">
                    Debes ingresar tu nombre y apellido.
                  </div>
                </div>
              </div>

              <div className="col-sm-6">
                <label htmlFor="rut" className="form-label campo-requerido">
                  Rut
                </label>
                <div className="input-group">
                  <span
                    className={
                      isDay === "dia"
                        ? "input-group-text"
                        : "input-group-text bg-black"
                    }
                    id="inputGroupPrepend"
                  >
                    <i
                      className={
                        isDay === "dia"
                          ? "bi bi-person"
                          : "bi bi-person text-white"
                      }
                    ></i>
                  </span>
                  <input
                    value={values.rut}
                    onChange={handleChange}
                    type="text"
                    className={
                      isDay === "dia"
                        ? "form-control rounded-end"
                        : "form-control rounded-end bg-dark text-white"
                    }
                    aria-describedby="inputGroupPrepend"
                    name="rut"
                    required
                    placeholder="Sin puntos, solo guión"
                  />
                  <div className="invalid-feedback">
                    El rut ingresado no es válido.
                  </div>
                </div>
              </div>

              <div className="col-sm-6">
                <label htmlFor="email" className="form-label campo-requerido">
                  Email
                </label>
                <div className="input-group  has-validation">
                  <span
                    className={
                      isDay === "dia"
                        ? "input-group-text"
                        : "input-group-text bg-black"
                    }
                  >
                    <i
                      className={
                        isDay === "dia"
                          ? "bi bi-envelope"
                          : "bi bi-envelope text-white"
                      }
                    ></i>
                  </span>
                  <input
                    value={values.email}
                    onChange={handleChange}
                    type="email"
                    className={
                      isDay === "dia"
                        ? "form-control rounded-end"
                        : "form-control rounded-end bg-dark text-white placeholderWhite"
                    }
                    name="email"
                    id="email"
                    placeholder="correo@dominio.com"
                    required
                  />
                  <div className="invalid-feedback">
                    Debes ingresar tu correo electrónico.
                  </div>
                </div>
              </div>

              <div className="col-sm-6">
                <label
                  htmlFor="numeroCelular"
                  className="form-label campo-requerido"
                >
                  Número celular
                </label>
                <div className="input-group">
                  <span
                    className={
                      isDay === "dia"
                        ? "input-group-text"
                        : "input-group-text bg-black"
                    }
                  >
                    <i
                      className={
                        isDay === "dia"
                          ? "bi bi-telephone"
                          : "bi bi-telephone text-white"
                      }
                    ></i>
                  </span>
                  <input
                    value={values.numeroCelular}
                    onChange={handleChange}
                    type="tel"
                    className={
                      isDay === "dia"
                        ? "form-control rounded-end"
                        : "form-control rounded-end bg-dark text-white placeholderWhite"
                    }
                    name="numeroCelular"
                    required
                    placeholder="ej: +569 88887777"
                  />
                  <div className="invalid-feedback">
                    Debes ingresar tu número celular para contactarte.
                  </div>
                </div>
              </div>

              <div className="col-sm-6">
                <label htmlFor="cargo" className="form-label">
                  Cargo
                </label>
                <div className="input-group">
                  <span
                    className={
                      isDay === "dia"
                        ? "input-group-text"
                        : "input-group-text bg-black"
                    }
                    id="inputGroupPrepend"
                  >
                    <i
                      className={
                        isDay === "dia" ? "bi bi-gear" : "bi bi-gear text-white"
                      }
                    ></i>
                  </span>
                  <input
                    id="cargo"
                    value={values.cargo}
                    onChange={handleChange}
                    type="text"
                    className={
                      isDay === "dia"
                        ? "form-control rounded-end"
                        : "form-control rounded-end bg-dark text-white"
                    }
                    aria-describedby="inputGroupPrepend"
                    name="cargo"
                    placeholder="profesión/ oficio"
                  />
                </div>
              </div>

              <div className="col-sm-6">
                <label className="form-label">¿Tienes una empresa?</label>
                <div className="input-group px-3">
                  <div className="form-check">
                    <input
                      name="tieneEmpresa"
                      value="no"
                      id="no"
                      checked={tieneEmpresa === "no"}
                      onChange={onOptionChange}
                      className="form-check-input"
                      type="radio"
                    />
                    <label
                      className="form-check-label"
                      htmlFor="noTieneEmpresa"
                    >
                      No
                    </label>
                  </div>

                  <span className="mx-2"></span>
                  <div className="form-check">
                    <input
                      name="tieneEmpresa"
                      value="si"
                      id="si"
                      checked={tieneEmpresa === "si"}
                      onChange={onOptionChange}
                      className="form-check-input"
                      type="radio"
                    />
                    <label className="form-check-label" htmlFor="tieneEmpresa">
                      Si
                    </label>
                  </div>
                </div>
              </div>

              <div className="col-sm-6" hidden={tieneEmpresa != "si"}>
                <label htmlFor="nombreEmpresa" className="form-label">
                  Nombre empresa
                </label>
                <div className="input-group">
                  <span
                    className={
                      isDay === "dia"
                        ? "input-group-text"
                        : "input-group-text bg-black"
                    }
                  >
                    <i
                      className={
                        isDay === "dia"
                          ? "bi bi-building"
                          : "bi bi-building text-white"
                      }
                    ></i>
                  </span>
                  <input
                    value={values.nombreEmpresa}
                    onChange={handleChange}
                    type="text"
                    className={
                      isDay === "dia"
                        ? "form-control rounded-end"
                        : "form-control rounded-end bg-dark text-white placeholderWhite"
                    }
                    name="nombreEmpresa"
                  />
                </div>
              </div>

              <div className="col-sm-6" hidden={tieneEmpresa != "si"}>
                <label htmlFor="razonSocial" className="form-label">
                  Razón social:
                </label>
                <div className="input-group">
                  <span
                    className={
                      isDay === "dia"
                        ? "input-group-text"
                        : "input-group-text bg-black"
                    }
                  >
                    <i
                      className={
                        isDay === "dia"
                          ? "bi bi-fonts"
                          : "bi bi-fonts text-white"
                      }
                    ></i>
                  </span>
                  <input
                    value={values.razonSocial}
                    onChange={handleChange}
                    type="text"
                    className={
                      isDay === "dia"
                        ? "form-control rounded-end"
                        : "form-control rounded-end bg-dark text-white placeholderWhite"
                    }
                    name="razonSocial"
                  />
                </div>
              </div>

              <div className="col-sm-6" hidden={tieneEmpresa != "si"}>
                <label htmlFor="rubro" className="form-label">
                  Rubro
                </label>
                <div className="input-group">
                  <span
                    className={
                      isDay === "dia"
                        ? "input-group-text"
                        : "input-group-text bg-black"
                    }
                  >
                    <i
                      className={
                        isDay === "dia"
                          ? "bi bi-fonts"
                          : "bi bi-fonts text-white"
                      }
                    ></i>
                  </span>
                  <input
                    value={values.rubro}
                    onChange={handleChange}
                    type="text"
                    className={
                      isDay === "dia"
                        ? "form-control rounded-end"
                        : "form-control rounded-end bg-dark text-white placeholderWhite"
                    }
                    name="rubro"
                    placeholder="a que se dedican?"
                  />
                </div>
              </div>

              <div className="col-sm-6" hidden={tieneEmpresa != "si"}>
                <label htmlFor="webEmpresa" className="form-label">
                  Sitio web empresa
                </label>
                <div className="input-group">
                  <span
                    className={
                      isDay === "dia"
                        ? "input-group-text"
                        : "input-group-text bg-black"
                    }
                  >
                    <i
                      className={
                        isDay === "dia"
                          ? "bi bi-window"
                          : "bi bi-window text-white"
                      }
                    ></i>
                  </span>
                  <input
                    value={values.webEmpresa}
                    onChange={handleChange}
                    type="text"
                    className={
                      isDay === "dia"
                        ? "form-control rounded-end"
                        : "form-control rounded-end bg-dark text-white placeholderWhite"
                    }
                    name="webEmpresa"
                    placeholder="www.dominio.cl"
                  />
                </div>
              </div>

              <div className="col-12">
                <label
                  htmlFor="idTipoConsulta"
                  className="form-label campo-requerido"
                >
                  Tipo de Consulta
                </label>
                <div className="input-group">
                  <span
                    className={
                      isDay === "dia"
                        ? "input-group-text"
                        : "input-group-text bg-black"
                    }
                  >
                    <i
                      className={
                        isDay === "dia"
                          ? "bi bi-list-nested"
                          : "bi bi-list-nested text-white"
                      }
                    ></i>
                  </span>
                  <select
                    onChange={handleChange}
                    name="idTipoConsulta"
                    id="idTipoConsulta"
                    className={
                      isDay === "dia"
                        ? "form-select rounded-end"
                        : "form-select rounded-end bg-dark text-light"
                    }
                    value={values.idTipoConsulta}
                    required
                  >
                    <option value="">Elija una opción</option>
                    {tiposConsulta.map((tipoConsulta, index) => {
                      return (
                        <option key={index} value={tipoConsulta.id}>
                          {tipoConsulta.tipo}
                        </option>
                      );
                    })}
                  </select>
                  <div className="invalid-feedback">
                    Debes seleccionar una opción.
                  </div>
                </div>
              </div>

              <div className="col-12">
                <label
                  htmlFor="consulta"
                  className="form-label campo-requerido"
                >
                  Consulta
                </label>
                <div className="input-group">
                  <span
                    className={
                      isDay === "dia"
                        ? "input-group-text"
                        : "input-group-text bg-black"
                    }
                  >
                    <i
                      className={
                        isDay === "dia"
                          ? "bi bi-fonts"
                          : "bi bi-fonts text-white"
                      }
                    ></i>
                  </span>
                  <textarea
                    value={values.consulta}
                    minLength={10}
                    onChange={handleChange}
                    className={
                      isDay === "dia"
                        ? "form-control rounded-end"
                        : "form-control rounded-end bg-dark text-white placeholderWhite"
                    }
                    rows={8}
                    name="consulta"
                    placeholder="Aquí puedes detallar tu consulta"
                    required
                  ></textarea>
                  <div className="invalid-feedback">
                    Debes ingresar la descripcion de tu consulta.
                  </div>
                </div>
              </div>

              <div className="d-grid gap-2 d-md-flex justify-content-md-center mt-5">
                {/*<button  className="btn btn-primary btn-lg px-4 me-md-2" data-bs-toggle="modal" data-bs-target="#example-modal">Enviar</button>*/}
                <button
                  type="submit"
                  onClick={validar}
                  className="btn btn-primary btn-lg px-4 me-md-2"
                >
                  Enviar
                </button>
              </div>
            </div>
          </form>
        </div>
      </div>

      {/* modal de formulario enviado */}
      <div
        className="modal fade"
        id="example-modal"
        tabIndex="-1"
        aria-labelledby="exampleModalLabel"
      >
        <div className="modal-dialog">
          <div className="modal-content">
            <div className="modal-header">
              <h5 className="modal-title mx-auto" id="exampleModalLabel">
                ¡Formulario de contacto enviado!
              </h5>
            </div>

            <div className="modal-body">
              <img
                onContextMenu={(e) => e.preventDefault()}
                src={ChicaOk}
                className="img-fluid px-2"
                alt=""
              />

              <div className="py-3">
                <div className="mb-2">
                  El formulario de contacto se ha enviado correctamente, pronto
                  nos prondremos en contacto contigo.
                </div>
                <div>
                  ¡El sistema enviará una copia de esta solicitud a tu correo!
                </div>
              </div>
            </div>
            <div className="modal-footer mx-auto">
              <button
                type="button"
                className="btn btn-lg btn-danger my-2"
                data-bs-dismiss="modal"
                onClick={() => history(0)}
              >
                Muy Bien
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default PrincipalContacto;
