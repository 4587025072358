import WebAmarillo from "../../images/muestra/web-sample-amarillo-foto.png";
import WebMorado from "../../images/muestra/web-sample-morado-ondas.png";
import WebVerde from "../../images/muestra/web-sample-verde-viaje.png";
import WebAzul from "../../images/muestra/web-sample-azul-cuenta.png";
import LogoBlanco from "../../images/logo/logo-amatica-bordes-blanco.png";
import LogoNegro from "../../images/logo/logo-amatica-bordes-negro.png";
import BrunoMars from "../../images/muestra/bruno-mars.jpg";
import AlaAvion from "../../images/muestra/ala-avion.jpg";
import ParejaPlaya from "../../images/muestra/pareja-playa.jpg";
import MapaAuto from "../../images/muestra/mapa-auto.jpg";
import HombreMontana from "../../images/muestra/hombre-montana.jpg";
import BurbujaNaranja from "../../images/muestra/burbuja-naranja-morada.png";
import IrisTecno from "../../images/muestra/iris-tecno.jpeg";
import ServidorAzure from "../../images/muestra/servidor-azure.jpg";
import PinturaAbs from "../../images/muestra/pintura-abs.png";
import AcuarelaMar from "../../images/muestra/aquarela-mar-esquina.png";
import NaveEspacial from "../../images/muestra/nave-espacial.png";
import LogoAzure from "../../images/muestra/logo-azure.png";
import LogoAmd from "../../images/muestra/logo-amd.png";
import LogoNature from "../../images/muestra/logo-nature.png";
import NaveEspacialConstruccion from "../../images/muestra/nave-espacial-contruccion.png";
import HombrePensante from "../../images/muestra/hombre-pensante.png";
import ParejaViajando from "../../images/muestra/pareja-viajando.png";

import GraficosMuestra from "./ScriptsMuestra.js";
import "./Muestra.css";
import "../../index.css";
import { useState, useEffect } from "react";

function PrincipalMuestras({ isDay }) {
  //             style={{ backgroundImage: `url(${Triangulos})`, backgroundRepeat: "no-repeat", backgroundPosition: "center top", backgroundSize: "100%", backgroundColor:"purple" }}>
  const [selectMuestra, setSelectMuestra] = useState("1");
  const [reproductor, setReproductor] = useState(30);
  const [tiempoReproductor, setTiempoReproductor] = useState("0:30");
  const [tiempoNRep, setTiempoNRep] = useState("2:47");

  function reproductorChange(evt) {
    setReproductor(evt.target.value);

    const horas = parseInt(evt.target.value / 60);
    let minutos = evt.target.value % 60;
    if (minutos < 10) minutos = "0" + minutos;

    const tiempo = horas + ":" + minutos;
    setTiempoReproductor(tiempo);

    const conteoN = 197 - evt.target.value;
    const horas2 = parseInt(conteoN / 60);
    let minutos2 = conteoN % 60;
    if (minutos2 < 10) minutos2 = "0" + minutos2;

    const tiempo2 = horas2 + ":" + minutos2;
    setTiempoNRep(tiempo2);
  }

  // CAMBIO DE TEXTOS WEB
  const textoWebP = [
    "Diseño cautivador: Nuestro equipo de diseñadores talentosos se dedica a crear experiencias web cautivadoras. Cada sitio que construimos es una obra maestra visual que refleja la esencia única de tu marca, atrayendo a tus visitantes desde el primer vistazo.",
    "Desarrollo responsive: Sabemos que la accesibilidad es clave. Desarrollamos sitios web totalmente responsive que se adaptan a cualquier dispositivo, garantizando una experiencia uniforme y atractiva, ya sea en una computadora de escritorio, tablet o smartphone.",
    "Experiencia de usuario (UX): La experiencia del usuario es el corazón de nuestros desarrollos web. Cada interacción se diseña pensando en la comodidad del usuario, desde la navegación intuitiva hasta la velocidad de carga rápida.",
    "Funcionalidad robusta: Más allá de lo estético, nos aseguramos de que tu sitio web sea funcional y eficiente. Integraremos las características necesarias para satisfacer las demandas específicas de tu negocio, ya sea a través de sistemas de comercio electrónico, formularios interactivos o cualquier otra funcionalidad personalizada.",
  ];
  const fondoWebP = ["#f5c15e", "#7bdee4", "#01b6f7", "#7c24c4"];

  const [currentTextoWebP, setCurrentTextoWebP] = useState(0);
  useEffect(() => {
    window.scrollTo(0, 0); // Esto hará que el scroll vaya al inicio cada vez que se cargue el componente

    const interval = setInterval(() => {
      setCurrentTextoWebP((currentTextoWebP) => {
        if (currentTextoWebP >= 3) return 0;
        return currentTextoWebP + 1;
      });
    }, 5000);
    return () => clearInterval(interval);
  }, []);

  // CONTADOR DE SEGUNDOS
  /*
    const [seconds, setSeconds] = useState(0);
    useEffect(() => {
        const interval = setInterval(() => {
            setSeconds(seconds => {
                return seconds + 1;
            });
        }, 1000);
        return () => clearInterval(interval);
    }, []);
    */

  return (
    <div
      className={isDay === "dia" ? "mx-auto pb-5" : "mx-auto pb-5 text-light"}
    >
      <title>Muestras y Ejemplos de Amática</title>

      <div style={{ backgroundImage: "linear-gradient(red, purple)" }}>
        <div className="col-12 p-3"></div>
        <div className="col-lg-8 col-11 text-light text-center mx-auto">
          <h1 className="display-4 fw-bold lh-1 my-4">
            Despega hoy con el desarrollo digital junto a Amática
          </h1>
          <div className="col-12 p-lg-3 p-1"></div>
          <p className="h2 py-lg-4 py-2 lh-1">
            Creemos experiencias extraordinarias. Calidad y creatividad en cada
            código. ¡Haz despegar tu presencia digital con Amática!
          </p>
        </div>

        <div className="col-12 p-3"></div>
        <div className="col-lg-8 col-11  mx-auto">
          <img
            onContextMenu={(e) => e.preventDefault()}
            src={NaveEspacialConstruccion}
            className="img-fluid"
            alt=""
            loading="lazy"
          />
        </div>
        <div className="col-12 p-3"></div>
        <div className="col-lg-8 col-11 text-light display-6 mx-auto text-center fw-bold lh-1 redondo">
          Nuestra creatividad viaja mas allá de la estratósfera, dejanos
          mostrarte algunos ejemplos...
        </div>
        <div className="col-12 p-lg-5 p-3"></div>
      </div>

      <div className="col-12 p-lg-4 p-2"></div>

      {/** HOMBRE PENSANTE **/}
      <div className="">
        <div className="row col-lg-10 col-11 mx-auto">
          <div className="col-lg-4 col-12">
            <h1 className="display-5 fw-bold lh-1 mb-3">
              Explora el diseño web redefinido con Amatica,
            </h1>
            <h1
              className="display-6 fw-bold lh-1 mb-3"
              style={{
                color: "purple",
                WebkitTextStrokeWidth: `${isDay === "dia" ? "0px" : "0.5px"}`,
                WebkitTextStrokeColor: "white",
              }}
            >
              donde cada pixel cuenta una historia de innovación visual.
            </h1>
            <p className="lead">
              Desde interfaces intuitivas hasta layouts envolventes, nuestro
              enfoque en el diseño web va más allá de lo estético. Fusionamos la
              creatividad con la funcionalidad, garantizando que cada elemento
              no solo sea hermoso, sino que también cumpla con su propósito
              estratégico.
            </p>
          </div>

          <div className="col-lg-4 col-12">
            <img
              onContextMenu={(e) => e.preventDefault()}
              src={HombrePensante}
              className="img-fluid"
              alt=""
              loading="lazy"
            />
          </div>

          <div className="col-lg-4 col-12">
            <p className="lead">
              Nos enorgullece ofrecer sitios web responsive, optimizados para
              SEO y equipados con las últimas tendencias de diseño. Ya sea una
              plataforma de comercio electrónico, un blog corporativo o una
              aplicación interactiva,
            </p>
            <h1
              className="display-6 fw-bold lh-1 mb-3"
              style={{
                color: "purple",
                WebkitTextStrokeWidth: `${isDay === "dia" ? "0px" : "0.5px"}`,
                WebkitTextStrokeColor: "white",
              }}
            >
              nuestro equipo de diseñadores web trabaja incansablemente para
              crear soluciones
            </h1>
            <h1 className="display-5 fw-bold lh-1 mb-3">
              que se destacan en el competitivo paisaje digital.
            </h1>
          </div>
        </div>
      </div>

      <div className="col-12 p-lg-4 p-2"></div>

      <div
        id="carouselExampleAutoplaying"
        className="carousel slide carousel-fade col-lg-10 col-11 mx-auto"
        data-bs-ride="carousel"
      >
        <div className="carousel-indicators row text-center mx-auto">
          <button
            type="button"
            data-bs-target="#carouselExampleIndicators"
            data-bs-slide-to="0"
            className="active"
            aria-current="true"
            aria-label="Slide 1"
          ></button>
          <button
            type="button"
            data-bs-target="#carouselExampleIndicators"
            data-bs-slide-to="1"
            aria-label="Slide 2"
          ></button>
          <button
            type="button"
            data-bs-target="#carouselExampleIndicators"
            data-bs-slide-to="2"
            aria-label="Slide 3"
          ></button>
        </div>

        <div className="carousel-inner">
          <div className="carousel-item active">
            {/** BANER BURBUJA **/}
            <div
              className="row border border-3 border-dark d-flex align-items-center text-light px-4"
              style={{
                backgroundImage: "linear-gradient(#74097d, #f00082)",
                minHeight: "500px",
              }}
            >
              <div className="d-block d-lg-none p-3"></div>

              <div className="col-12 col-lg-6">
                <h1 className="display-6 mb-3">
                  <img
                    onContextMenu={(e) => e.preventDefault()}
                    src={LogoNature}
                    className="img-fluid"
                    loading="lazy"
                    alt=""
                    style={{ height: "50px" }}
                  />
                  Soul Nature
                </h1>
                <p className="lead">
                  🌿 Descubre el poder de lo orgánico con Soul Nature 🌿
                </p>
                <p className="h4 fst-italic fw-medium">
                  Les presentamos una exquisita gama de productos orgánicos que
                  reflejan nuestra pasión por la salud y el respeto por nuestro
                  planeta.{" "}
                </p>
                <p className="h4 fst-italic fw-medium">
                  Nos esforzamos por ofrecer alternativas conscientes y
                  sostenibles que te permitan disfrutar de la pureza de la
                  naturaleza en cada aspecto de tu vida.
                </p>
                <p className="lead">
                  🍃 Alimentos frescos | 🌸 Cuidado personal natural | 🌎
                  Compromiso ecológico
                </p>
              </div>
              <div className="col-12 col-lg-6">
                <img
                  width="500"
                  onContextMenu={(e) => e.preventDefault()}
                  src={BurbujaNaranja}
                  className="img-fluid"
                  alt=""
                  loading="lazy"
                />
              </div>
              <div className="d-block d-lg-none p-3"></div>
            </div>
          </div>

          <div className="carousel-item">
            {/** BANER NAVE ESPACIAL **/}
            <div
              className="row border border-3 border-dark d-flex align-items-center"
              style={{
                minHeight: "500px",
                backgroundPosition: "center",
                backgroundImage: "url('https://i.gifer.com/72eL.gif')",
                backgroundSize: "cover",
              }}
            >
              <div className="col-12 col-lg-8 row text-light text-center mx-auto order-lg-2">
                <div className="d-block d-lg-none p-3"></div>
                <div className="col-6 ms-0 ps-0 text-start mt-auto">
                  <div
                    className="p-3 rounded-3"
                    style={{ backgroundColor: "rgba(239, 111, 123, 0.8)" }}
                  >
                    Empujando los límites de la exploración cósmica, nuestra
                    asociación de investigación espacial se embarca en un viaje
                    hacia lo desconocido.
                  </div>
                </div>
                <div className="col-6 me-0 pe-0 text-end mt-auto">
                  <div
                    className="p-3 rounded-3"
                    style={{ backgroundColor: "rgba(204, 77, 117, 0.8)" }}
                  >
                    Con el compromiso de desentrañar los misterios del universo,
                    trabajamos incansablemente en la vanguardia de la ciencia
                    espacial.
                  </div>
                </div>

                <h1 className="col-12 display-6 fw-bold text-light my-4">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="48"
                    height="48"
                    fill="currentColor"
                    className="bi bi-rocket-takeoff-fill texto-blanco"
                    viewBox="0 0 16 16"
                  >
                    <path d="M12.17 9.53c2.307-2.592 3.278-4.684 3.641-6.218.21-.887.214-1.58.16-2.065a3.578 3.578 0 0 0-.108-.563 2.22 2.22 0 0 0-.078-.23V.453c-.073-.164-.168-.234-.352-.295a2.35 2.35 0 0 0-.16-.045 3.797 3.797 0 0 0-.57-.093c-.49-.044-1.19-.03-2.08.188-1.536.374-3.618 1.343-6.161 3.604l-2.4.238h-.006a2.552 2.552 0 0 0-1.524.734L.15 7.17a.512.512 0 0 0 .433.868l1.896-.271c.28-.04.592.013.955.132.232.076.437.16.655.248l.203.083c.196.816.66 1.58 1.275 2.195.613.614 1.376 1.08 2.191 1.277l.082.202c.089.218.173.424.249.657.118.363.172.676.132.956l-.271 1.9a.512.512 0 0 0 .867.433l2.382-2.386c.41-.41.668-.949.732-1.526l.24-2.408Zm.11-3.699c-.797.8-1.93.961-2.528.362-.598-.6-.436-1.733.361-2.532.798-.799 1.93-.96 2.528-.361.599.599.437 1.732-.36 2.531Z" />
                    <path d="M5.205 10.787a7.632 7.632 0 0 0 1.804 1.352c-1.118 1.007-4.929 2.028-5.054 1.903-.126-.127.737-4.189 1.839-5.18.346.69.837 1.35 1.411 1.925Z" />
                  </svg>
                  <span className="texto-blanco ">Rocket Source</span>{" "}
                  <div className="text-warning h2">
                    Desarrollo e investigación espacial
                  </div>
                </h1>

                <div className="col-6 ms-0 ps-0 text-start mb-auto">
                  <div
                    className="p-3 rounded-3"
                    style={{ backgroundColor: "rgba(140, 54, 104, 0.8)" }}
                  >
                    Únete a nosotros en este emocionante viaje de
                    descubrimiento, donde la curiosidad es nuestra brújula y el
                    conocimiento es nuestro destino.
                  </div>
                </div>
                <div className="col-6 me-0 pe-0 text-end mb-auto">
                  <div
                    className="p-3 rounded-3"
                    style={{ backgroundColor: "rgba(88, 45, 85, 0.8)" }}
                  >
                    Juntos, avanzamos hacia las estrellas, ampliando los
                    horizontes de la comprensión humana. ¡Bienvenido al futuro
                    de la exploración espacial.
                    <div className="text-primary fw-bold">
                      #RocketSource #InvestigaciónEspacial
                      #DescubrimientosCósmicos #ExploraciónIlimitada
                    </div>
                  </div>
                </div>
              </div>

              <div className="d-block d-lg-none p-3"></div>

              <div className="col-12 col-lg-4 text-start ms-0 ps-0 pb-0 mt-auto order-lg-1">
                <img
                  width="400"
                  onContextMenu={(e) => e.preventDefault()}
                  src={NaveEspacial}
                  className="img-fluid"
                  alt=""
                  loading="lazy"
                />
              </div>
            </div>
          </div>

          <div className="carousel-item">
            {/** BANER PINTURA **/}
            <div
              className="row border border-3 border-dark d-flex align-items-center text-dark"
              style={{ backgroundColor: "#d2fff5", minHeight: "500px" }}
            >
              <div className="d-none d-lg-block col-lg-4 text-start ms-0 ps-0">
                <img
                  height="500"
                  onContextMenu={(e) => e.preventDefault()}
                  src={AcuarelaMar}
                  className=""
                  alt=""
                  loading="lazy"
                />
              </div>

              <div className="col-12 col-lg-4 text-end me-0 pe-0 order-lg-2">
                <img
                  height="500"
                  onContextMenu={(e) => e.preventDefault()}
                  src={PinturaAbs}
                  className=""
                  alt=""
                  loading="lazy"
                />
              </div>

              <div
                className="col-12 col-lg-4 col-start order-lg-1"
                style={{ fontFamily: "'Arial, Helvetica', 'sans-serif'" }}
              >
                <h1 className="display-6 fw-bold mb-3">
                  Explora el Arte con Waves
                </h1>
                <p className="h4">
                  Bienvenido a Waves: Donde el Arte Encuentra su Marea Creativa
                </p>
                <p className="lead">
                  No hay límites para tu imaginación. Nos enorgullece ser el
                  hogar de artistas de todos los rincones del mundo, creando un
                  espacio donde las olas de la creatividad se entrelazan para
                  formar una obra maestra única.
                </p>
                <div className="text-center">
                  <i
                    style={{ fontSize: "40px" }}
                    className="bi bi-instagram me-3"
                  ></i>
                  <i
                    style={{ fontSize: "40px" }}
                    className="bi bi-facebook me-3"
                  ></i>
                  <i style={{ fontSize: "40px" }} className="bi bi-twitch "></i>
                </div>
              </div>

              <div className="d-block d-lg-none p-3"></div>
            </div>
          </div>
        </div>

        <button
          className="carousel-control-prev"
          type="button"
          data-bs-target="#carouselExampleAutoplaying"
          data-bs-slide="prev"
        >
          <span
            className="carousel-control-prev-icon"
            aria-hidden="true"
          ></span>
          <span className="visually-hidden">Previous</span>
        </button>
        <button
          className="carousel-control-next"
          type="button"
          data-bs-target="#carouselExampleAutoplaying"
          data-bs-slide="next"
        >
          <span
            className="carousel-control-next-icon"
            aria-hidden="true"
          ></span>
          <span className="visually-hidden">Next</span>
        </button>
      </div>

      <div className="col-12 p-3"></div>

      {/** BANNERS PEQUEÑOS **/}
      <div className="row col-10 mx-auto border border-3 border-dark">
        <div
          className="col-12 col-md-6 text-white mx-auto"
          style={{
            backgroundImage: `url(${ServidorAzure})`,
            backgroundRepeat: "no-repeat",
            backgroundPosition: "center",
          }}
        >
          <div className="col-12 altura-modal-fijo"></div>

          <div
            className="col-11 text-center mx-auto p-4"
            style={{ backgroundColor: "rgba(0, 0, 0, 0.5)" }}
          >
            <img
              onContextMenu={(e) => e.preventDefault()}
              src={LogoAzure}
              className="img-fluid"
              loading="lazy"
              alt=""
              style={{ height: "50px" }}
            />

            <p className="h5 mt-3 col-12 myDIV">
              Descubre a Azure, tu solución tecnológica.
            </p>
            <div className="hide h5 fw-light">
              Somos una plataforma de computación en la nube creado por
              Microsoft para construir, probar, desplegar y administrar
              aplicaciones y servicios mediante el uso de sus centros de datos.
            </div>
          </div>
        </div>

        <div
          className="col-12 col-md-6 text-white mx-auto"
          style={{
            backgroundImage: `url(${IrisTecno})`,
            backgroundRepeat: "no-repeat",
            backgroundPosition: "center",
          }}
        >
          <div className="col-12 altura-modal-fijo"></div>

          <div
            className="col-11 text-center mx-auto p-4"
            style={{ backgroundColor: "rgba(204, 204, 204, 0.4)" }}
          >
            <img
              onContextMenu={(e) => e.preventDefault()}
              src={LogoAmd}
              className="img-fluid"
              loading="lazy"
              alt=""
              style={{ height: "50px" }}
            />

            <p className="h5 mt-3 col-12 text-dark myDIV">
              AMD, lider en procesamiento de datos masivos.
            </p>
            <div className="hide text-light h5 fw-light">
              Los estudios más importantes del mundo utilizan tecnología AMD
              para producir y entregar sus creaciones. Nos apasiona la creación
              de películas.
            </div>
          </div>
        </div>
      </div>

      <div className="col-12 p-lg-4 p-2"></div>
      <div className="col-lg-10 col-11 mx-auto">
        <GraficosMuestra isDay={isDay} />
      </div>
      <div className="col-12 p-lg-4 p-2"></div>

      <div style={{ backgroundImage: "linear-gradient(purple, blue)" }}>
        <div className="col-12 p-3"></div>
        <div className="col-lg-8 col-11 text-light text-center mx-auto">
          <h1 className="display-4 fw-bold lh-1 my-4">
            Despega hoy con el desarrollo digital junto a Amática
          </h1>
          <div className="col-12 p-lg-3 p-1"></div>
          <p className="h2 py-lg-4 py-2 lh-1">
            Creemos experiencias extraordinarias. Calidad y creatividad en cada
            código. ¡Haz despegar tu presencia digital con Amática!
          </p>
        </div>

        <div className="col-12 p-3"></div>
        <div className="col-lg-8 col-11  mx-auto">
          <img
            onContextMenu={(e) => e.preventDefault()}
            src={ParejaViajando}
            className="img-fluid"
            alt=""
            loading="lazy"
          />
        </div>
        <div className="col-12 p-3"></div>
        <div className="col-lg-8 col-11 text-light display-6 mx-auto text-center fw-bold lh-1 redondo">
          Nuestra creatividad viaja mas allá de la estratósfera, dejanos
          mostrarte algunos ejemplos...
        </div>
        <div className="col-12 p-lg-5 p-3"></div>
      </div>

      <div className="col-12 p-lg-4 p-2"></div>

      <div className="row col-10 mx-auto esconder-grande py-3 bg-light rounded-3">
        <div className="col-6 d-flex">
          <div className="col-12 ms-auto mt-auto mb-0">
            <div className="col-12">
              <h1 className="display-5 fw-bold col-lg-8 mx-auto">
                Desarrollo a la medida
              </h1>
              <div className="col-lg-12 mx-auto">
                <p className="lead mt-4">
                  En Amática, no seguimos las tendencias; las creamos. Estamos
                  en la vanguardia de la tecnología para asegurarnos de que tu
                  software no solo sea relevante hoy, sino también mañana para
                  uso constante y eficiente.
                </p>
              </div>
            </div>

            <div className="col-12 p-3"></div>

            <div className="col-8 ms-auto mt-auto">
              <button
                type="button"
                className="btn focus-ring focus-ring-dark border btn-light rounded border border-dark-subtle text-start px-3 shadow"
                onClick={() => setSelectMuestra("0")}
              >
                <div className="d-flex">
                  <b className="me-3">Grupo de logística</b>
                  <p className={selectMuestra === "1" ? "d-none" : ""}>
                    <i className="bi bi-check-circle-fill text-success"></i>
                  </p>
                </div>
                <h6 className="text-black-50">
                  Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed
                  do eiusmod tempor incididunt ut labore et dolore magna aliqua.
                </h6>
                <div className="mt-3 mb-2">
                  <span className="bg-dark rounded px-2 text-light">
                    460 Usuarios
                  </span>
                </div>
              </button>

              <button
                type="button"
                className="btn focus-ring focus-ring-dark border btn-light rounded border border-dark-subtle text-start px-3 mt-2 shadow"
                onClick={() => setSelectMuestra("1")}
              >
                <div className="d-flex">
                  <b className="me-3">Grupo de RRHH</b>
                  <p className={selectMuestra === "0" ? "d-none" : ""}>
                    <i className="bi bi-check-circle-fill text-success"></i>
                  </p>
                </div>
                <h6 className="text-black-50">
                  Ut enim ad minim veniam, quis nostrud exercitation ullamco
                  laboris nisi ut aliquip ex ea commodo consequat.
                </h6>
                <div className="mt-3 mb-2">
                  <span className="bg-dark rounded px-2 text-light">
                    181 Usuarios
                  </span>
                </div>
              </button>
            </div>
          </div>
        </div>

        <div className="col-6 ms-auto mt-auto mb-0">
          <div
            id="carouselExample2"
            className="carousel slide shadow redondo"
            data-bs-ride="carousel"
          >
            <div className="carousel-indicators">
              <button
                type="button"
                data-bs-target="#carouselExample2Indicators"
                data-bs-slide-to="0"
                className="active"
                aria-current="true"
                aria-label="Slide 1"
              ></button>
              <button
                type="button"
                data-bs-target="#carouselExample2Indicators"
                data-bs-slide-to="1"
                aria-label="Slide 2"
              ></button>
              <button
                type="button"
                data-bs-target="#carouselExample2Indicators"
                data-bs-slide-to="2"
                aria-label="Slide 3"
              ></button>
              <button
                type="button"
                data-bs-target="#carouselExample2Indicators"
                data-bs-slide-to="3"
                aria-label="Slide 4"
              ></button>
            </div>

            <div className="carousel-inner">
              <div className="carousel-item active" data-bs-interval="3000">
                <img
                  onContextMenu={(e) => e.preventDefault()}
                  src={AlaAvion}
                  className="d-block w-100 redondo"
                  alt=""
                  loading="lazy"
                />
                <div className="carousel-caption d-none d-md-block">
                  <h5>El viaje de tu vida</h5>
                  <p>
                    Some representative placeholder content for the first slide.
                  </p>
                </div>
              </div>
              <div className="carousel-item" data-bs-interval="3000">
                <img
                  onContextMenu={(e) => e.preventDefault()}
                  src={ParejaPlaya}
                  className="d-block w-100 redondo"
                  alt=""
                  loading="lazy"
                />
                <div className="carousel-caption d-none d-md-block">
                  <h5>No tiene que esperar más</h5>
                  <p>
                    Some representative placeholder content for the second
                    slide.
                  </p>
                </div>
              </div>
              <div className="carousel-item" data-bs-interval="3000">
                <img
                  onContextMenu={(e) => e.preventDefault()}
                  src={HombreMontana}
                  className="d-block w-100 redondo"
                  alt=""
                  loading="lazy"
                />
                <div className="carousel-caption d-none d-md-block">
                  <h5>Debes hacerlo mientras puedes</h5>
                  <p>
                    Some representative placeholder content for the second
                    slide.
                  </p>
                </div>
              </div>
              <div className="carousel-item" data-bs-interval="3000">
                <img
                  onContextMenu={(e) => e.preventDefault()}
                  src={MapaAuto}
                  className="d-block w-100 redondo"
                  alt=""
                  loading="lazy"
                />
                <div className="carousel-caption d-none d-md-block">
                  <h5>No importa cuál camino, importa llegar</h5>
                  <p>
                    Some representative placeholder content for the second
                    slide.
                  </p>
                </div>
              </div>
            </div>

            <button
              className="carousel-control-prev"
              type="button"
              data-bs-target="#carouselExample2"
              data-bs-slide="prev"
            >
              <span
                className="carousel-control-prev-icon"
                aria-hidden="true"
              ></span>
              <span className="visually-hidden">Previous</span>
            </button>
            <button
              className="carousel-control-next"
              type="button"
              data-bs-target="#carouselExample2"
              data-bs-slide="next"
            >
              <span
                className="carousel-control-next-icon"
                aria-hidden="true"
              ></span>
              <span className="visually-hidden">Next</span>
            </button>
          </div>
        </div>

        <div className="col-12 py-2"></div>

        <div className="col-lg-6 col-12">
          <div className="col-12">
            <div
              className="collapse"
              id="navbarToggleExternalContent"
              data-bs-theme="dark"
            >
              <div className="bg-dark p-4">
                <h5 className="text-body-emphasis h4">Collapsed content</h5>
                <span className="text-body-secondary">
                  Toggleable via the navbar brand.
                </span>
              </div>
            </div>

            <nav className="navbar navbar-dark bg-dark text-light rounded shadow">
              <div className="container-fluid">
                <button
                  disabled
                  className="navbar-toggler"
                  type="button"
                  data-bs-toggle="collapse"
                  data-bs-target="#navbarToggleExternalContent"
                  aria-controls="navbarToggleExternalContent"
                  aria-expanded="false"
                  aria-label="Toggle navigation"
                >
                  <span className="navbar-toggler-icon"></span>
                </button>

                <div className="text-start">
                  <img
                    onContextMenu={(e) => e.preventDefault()}
                    src={LogoBlanco}
                    className="img-fluid"
                    alt=""
                    width="40"
                    height="40"
                    loading="lazy"
                  />
                </div>

                <div className="">
                  <span className="border rounded p-2">
                    <i className="bi bi-brightness-high-fill"></i> /{" "}
                    <i className="bi bi-moon-fill"></i>
                  </span>
                  <span className="px-2"></span>
                  <span>
                    {" "}
                    v2.0
                    <i className="bi bi-caret-down-fill"></i>
                  </span>
                  <span className="px-2"></span>
                  <i className="bi bi-github" style={{ fontSize: "30px" }}></i>
                </div>
              </div>
            </nav>
          </div>

          <div className="col-12 p-2"></div>

          <div className="col-12 d-flex">
            <div className="col-6 p-4">
              <p className="lead mt-4">
                ¿Imaginas un software que se adapte perfectamente a las
                necesidades de tu negocio? En Amática, hacemos que esa visión se
                convierta en realidad. Cada línea de código está diseñada para
                impulsar tu éxito.
              </p>
              <p className="lead mt-4">
                No es solo software; es una experiencia. Creamos interfaces que
                no solo funcionan, sino que cautivan. La usabilidad es nuestra
                obsesión, y la innovación es nuestro sello.
              </p>
            </div>

            <div className="col-6 ms-auto">
              <div className="redondo bg-dark bg-gradient shadow">
                <div className="pt-3 px-3">
                  <img
                    onContextMenu={(e) => e.preventDefault()}
                    src={BrunoMars}
                    className="img-fluid redondo"
                    alt=""
                    loading="lazy"
                  />
                </div>

                <div className="py-2 text-light">
                  <div>Bruno Mars</div>
                  <div className="lead text-center">Just The Way You Are</div>
                </div>

                <div className="row d-flex py-2 text-light">
                  <div className="col text-start lead px-4">
                    <span className="px-2">{tiempoReproductor}</span>
                  </div>
                  <div className="col text-end lead px-4">
                    <span className="px-2">-{tiempoNRep}</span>
                  </div>

                  <div className="col-12">
                    <input
                      type="range"
                      value={reproductor}
                      onChange={reproductorChange}
                      min="0"
                      max="197"
                      className="form-range form-range-track-bg px-3"
                      name="reproductor"
                    />
                  </div>
                </div>

                <div className="bg-body-secondary redondo text-center d-flex align-items-center justify-content-center">
                  <div className="col-2 align-bot">
                    <i
                      className="bi bi-suit-heart-fill text-danger"
                      style={{ fontSize: "20px" }}
                    ></i>
                  </div>
                  <div className="col-1"></div>
                  <div className="col-2">
                    <i
                      className="bi bi-skip-start-fill"
                      style={{ fontSize: "30px" }}
                    ></i>
                  </div>
                  <div className="col-2">
                    <i
                      className="bi bi-play-circle-fill"
                      style={{ fontSize: "30px" }}
                    ></i>
                  </div>
                  <div className="col-2">
                    <i
                      className="bi bi-skip-end-fill"
                      style={{ fontSize: "30px" }}
                    ></i>
                  </div>
                  <div className="col-1"></div>
                  <div className="col-2 align-bot">
                    <i
                      className="bi bi-three-dots-vertical"
                      style={{ fontSize: "20px" }}
                    ></i>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="col-lg-6 col-12">
          <div className="col-12 d-flex">
            <div className="col-3">
              <div className="d-flex flex-column bg-light border border-primary shadow me-3 rounded-3">
                <div
                  className="d-block p-3 link-dark text-decoration-none"
                  title="Icon-only"
                  data-bs-toggle="tooltip"
                  data-bs-placement="right"
                >
                  <img
                    onContextMenu={(e) => e.preventDefault()}
                    src={LogoNegro}
                    className="img-fluid"
                    alt=""
                    width="40"
                    height="40"
                    loading="lazy"
                  />
                  <div className="col-12">Amática</div>
                  <span className="visually-hidden">Icon-only</span>
                </div>

                <ul className="nav nav-pills nav-flush flex-column mb-auto text-center px-3 rounded-3">
                  <li className="nav-item">
                    <div className="nav-link active py-2 border-bottom bg-gradient fs-3 rounded-3">
                      <i className="bi bi-house" width="240px" height="240"></i>
                    </div>
                  </li>
                  <li>
                    <div className="nav-link py-2 fs-3 border-bottom">
                      <i
                        className="bi bi-speedometer2 "
                        width="24"
                        height="24"
                      ></i>
                    </div>
                  </li>
                  <li>
                    <div className="nav-link py-2 fs-3 border-bottom">
                      <i className="bi bi-table" width="24" height="24"></i>
                    </div>
                  </li>
                  <li>
                    <div className="nav-link py-2 fs-3 border-bottom">
                      <i
                        className="bi bi-columns-gap"
                        width="24"
                        height="24"
                      ></i>
                    </div>
                  </li>
                  <li>
                    <div className="nav-link py-2 fs-3 border-bottom">
                      <i
                        className="bi bi-person-circle"
                        width="24"
                        height="24"
                      ></i>
                    </div>
                  </li>
                </ul>
                <div className="dropdown border-top">
                  <div
                    className="d-flex align-items-center justify-content-center p-3 link-dark text-decoration-none dropdown-toggle fs-3"
                    id="dropdownUser3"
                    data-bs-toggle="dropdown"
                    aria-expanded="false"
                  >
                    <img
                      src="https://github.com/mdo.png"
                      alt="mdo"
                      width="40"
                      height="40"
                      className="rounded-circle"
                    />
                  </div>
                  <ul
                    className="dropdown-menu text-small shadow"
                    aria-labelledby="dropdownUser3"
                  >
                    <li>
                      <div className="dropdown-item">New project...</div>
                    </li>
                    <li>
                      <div className="dropdown-item">Settings</div>
                    </li>
                    <li>
                      <div className="dropdown-item">Profile</div>
                    </li>
                    <li>
                      <hr className="dropdown-divider" />
                    </li>
                    <li>
                      <div className="dropdown-item">Sign out</div>
                    </li>
                  </ul>
                </div>
              </div>
            </div>

            <div className="col-9">
              <div
                className="dropdown-menu position-static d-flex flex-column flex-lg-row  p-3 rounded-3 shadow"
                data-bs-theme="light"
              >
                <nav className="col-lg-8">
                  <ul className="list-unstyled d-flex flex-column gap-2">
                    <li>
                      <div className="btn btn-hover-light puntero rounded-2 d-flex align-items-start gap-2 py-2 px-3 lh-sm text-start">
                        <i
                          className="bi bi-image-fill"
                          width="24"
                          height="24"
                        ></i>
                        <div>
                          <strong className="d-block">Main product</strong>
                          <small>Take a tour through the product</small>
                        </div>
                      </div>
                    </li>
                    <li>
                      <div className="btn btn-hover-light puntero rounded-2 d-flex align-items-start gap-2 py-2 px-3 lh-sm text-start">
                        <i
                          className="bi bi-music-note-beamed"
                          width="24"
                          height="24"
                        ></i>
                        <div>
                          <strong className="d-block">Another product</strong>
                          <small>Explore this other product we offer</small>
                        </div>
                      </div>
                    </li>
                    <li>
                      <div className="btn btn-hover-light puntero rounded-2 d-flex align-items-start gap-2 py-2 px-3 lh-sm text-start">
                        <i
                          className="bi bi-question-circle"
                          width="24"
                          height="24"
                        ></i>
                        <div>
                          <strong className="d-block">Support</strong>
                          <small>Get help from our support crew</small>
                        </div>
                      </div>
                    </li>
                  </ul>
                </nav>
                <div className="d-none d-lg-block vr mx-4 opacity-10">
                  &nbsp;
                </div>
                <hr className="d-lg-none" />

                <div className="col-lg-auto pe-3">
                  <nav>
                    <ul className="d-flex flex-column gap-2 list-unstyled small text-primary">
                      <li>
                        <u className="link-offset-2 link-underline link-underline-opacity-25 link-underline-opacity-75-hover puntero">
                          Documentation
                        </u>
                      </li>
                      <li>
                        <u className="link-offset-2 link-underline link-underline-opacity-25 link-underline-opacity-75-hover puntero">
                          Use cases
                        </u>
                      </li>
                      <li>
                        <u className="link-offset-2 link-underline link-underline-opacity-25 link-underline-opacity-75-hover puntero">
                          API status
                        </u>
                      </li>
                      <li>
                        <u className="link-offset-2 link-underline link-underline-opacity-25 link-underline-opacity-75-hover puntero">
                          Partners
                        </u>
                      </li>
                      <li>
                        <u className="link-offset-2 link-underline link-underline-opacity-25 link-underline-opacity-75-hover puntero">
                          Resources
                        </u>
                      </li>
                    </ul>
                  </nav>
                </div>
              </div>

              <div className="col-12 d-flex">
                <div className="col-8 mt-3 p-2 shadow rounded-3 bg-primary bg-gradient text-light">
                  <div className="d-grid">
                    <div className="cal">
                      <div className="cal-month">
                        <button className="btn cal-btn" disabled type="button">
                          <i
                            className="bi bi-arrow-left-short"
                            width="16"
                            height="16"
                          ></i>
                        </button>
                        <strong className="cal-month-name">Junio</strong>
                        <select
                          className="form-select cal-month-name d-none"
                          defaultValue="July"
                        >
                          <option value="January">Enero</option>
                          <option value="February">Febrero</option>
                          <option value="March">Marzo</option>
                          <option value="April">Abril</option>
                          <option value="May">Mayo</option>
                          <option value="June">Junio</option>
                          <option value="July">Julio</option>
                          <option value="August">Agosto</option>
                          <option value="September">Septiembre</option>
                          <option value="October">Octubre</option>
                          <option value="November">Noviembre</option>
                          <option value="December">Deciembre</option>
                        </select>
                        <button className="btn cal-btn" disabled type="button">
                          <i
                            className="bi bi-arrow-right-short"
                            width="16"
                            height="16"
                          ></i>
                        </button>
                      </div>
                      <div className="cal-weekdays lead">
                        <div className="cal-weekday">Lun</div>
                        <div className="cal-weekday">Mar</div>
                        <div className="cal-weekday">Mie</div>
                        <div className="cal-weekday">Jue</div>
                        <div className="cal-weekday">Vie</div>
                        <div className="cal-weekday">Sab</div>
                        <div className="cal-weekday">Dom</div>
                      </div>
                      <div className="cal-days text-white">
                        <button className="btn cal-btn" disabled type="button">
                          31
                        </button>

                        <button
                          className="btn cal-btn text-light"
                          type="button"
                        >
                          1
                        </button>
                        <button
                          className="btn cal-btn text-light"
                          type="button"
                        >
                          2
                        </button>
                        <button
                          className="btn cal-btn text-light"
                          type="button"
                        >
                          3
                        </button>
                        <button
                          className="btn cal-btn text-light"
                          type="button"
                        >
                          4
                        </button>
                        <button
                          className="btn cal-btn text-light"
                          type="button"
                        >
                          5
                        </button>
                        <button
                          className="btn cal-btn text-light"
                          type="button"
                        >
                          6
                        </button>
                        <button
                          className="btn cal-btn text-light"
                          type="button"
                        >
                          7
                        </button>

                        <button
                          className="btn cal-btn text-light"
                          type="button"
                        >
                          8
                        </button>
                        <button
                          className="btn cal-btn text-light"
                          type="button"
                        >
                          9
                        </button>
                        <button
                          className="btn cal-btn text-light"
                          type="button"
                        >
                          10
                        </button>
                        <button
                          className="btn cal-btn text-light"
                          type="button"
                        >
                          11
                        </button>
                        <button
                          className="btn cal-btn text-light"
                          type="button"
                        >
                          12
                        </button>
                        <button
                          className="btn cal-btn text-light"
                          type="button"
                        >
                          13
                        </button>
                        <button
                          className="btn cal-btn text-light"
                          type="button"
                        >
                          14
                        </button>

                        <button
                          className="btn cal-btn text-light"
                          type="button"
                        >
                          15
                        </button>
                        <button
                          className="btn cal-btn text-light"
                          type="button"
                        >
                          16
                        </button>
                        <button
                          className="btn cal-btn text-light"
                          type="button"
                        >
                          17
                        </button>
                        <button
                          className="btn cal-btn text-light"
                          type="button"
                        >
                          18
                        </button>
                        <button
                          className="btn cal-btn text-light"
                          type="button"
                        >
                          19
                        </button>
                        <button
                          className="btn cal-btn text-light"
                          type="button"
                        >
                          20
                        </button>
                        <button
                          className="btn cal-btn text-light"
                          type="button"
                        >
                          21
                        </button>

                        <button
                          className="btn cal-btn text-light"
                          type="button"
                        >
                          22
                        </button>
                        <button
                          className="btn cal-btn text-light"
                          type="button"
                        >
                          23
                        </button>
                        <button
                          className="btn cal-btn text-light"
                          type="button"
                        >
                          24
                        </button>
                        <button
                          className="btn cal-btn text-light"
                          type="button"
                        >
                          25
                        </button>
                        <button
                          className="btn cal-btn text-light"
                          type="button"
                        >
                          26
                        </button>
                        <button
                          className="btn cal-btn text-light"
                          type="button"
                        >
                          27
                        </button>
                        <button
                          className="btn cal-btn text-light"
                          type="button"
                        >
                          28
                        </button>

                        <button
                          className="btn cal-btn text-light"
                          type="button"
                        >
                          29
                        </button>
                        <button
                          className="btn cal-btn text-light"
                          type="button"
                        >
                          30
                        </button>
                        <button
                          className="btn cal-btn text-light"
                          type="button"
                        >
                          31
                        </button>

                        <button className="btn cal-btn" disabled type="button">
                          1
                        </button>
                        <button className="btn cal-btn" disabled type="button">
                          2
                        </button>
                        <button className="btn cal-btn" disabled type="button">
                          3
                        </button>
                      </div>
                    </div>
                  </div>
                </div>

                <div className="col-4 mt-3 ps-3">
                  <div className="bg-danger text-light bg-gradient mx-auto rounded-3 shadow p-2 text-start">
                    <div className="py-1 align-items-center puntero">
                      <i
                        className="bi bi-files pe-2"
                        width="16"
                        height="16"
                      ></i>
                      Documents
                    </div>

                    <div className="py-1 align-items-center puntero">
                      <i
                        className="bi bi-image-fill pe-2"
                        width="16"
                        height="16"
                      ></i>
                      Photos
                    </div>

                    <div className="py-1 align-items-center puntero">
                      <i className="bi bi-film pe-2" width="16" height="16"></i>
                      Movies
                    </div>

                    <div className="py-1 align-items-center puntero">
                      <i
                        className="bi bi-music-note-beamed pe-2"
                        width="16"
                        height="16"
                      ></i>
                      Music
                    </div>

                    <div className="pt-1 pb-3 align-items-center puntero">
                      <i
                        className="bi bi-joystick pe-2"
                        width="16"
                        height="16"
                      ></i>
                      Games
                    </div>
                    <hr className="dropdown-divider" />
                    <div className="py-2 align-items-center puntero">
                      <i className="bi bi-trash" width="16" height="16"></i>
                      Trash
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="col-12 p-3"></div>

      <div
        className={
          isDay === "dia"
            ? "col-10 mx-auto row bg-secondary-subtle"
            : "col-10 mx-auto row bg-primary"
        }
      >
        <div
          className="col-12 col-lg-6 px-0 mx-0 my-auto"
          style={{ pointerEvents: "none" }}
        >
          <div
            id="carouselExampleAutoplaying1"
            className="carousel slide"
            data-bs-ride="carousel"
          >
            <div className="carousel-inner">
              <div className="carousel-item active">
                <img
                  onContextMenu={(e) => e.preventDefault()}
                  src={WebAmarillo}
                  className="img-fluid"
                  alt=""
                  loading="lazy"
                />
              </div>
              <div className="carousel-item">
                <img
                  onContextMenu={(e) => e.preventDefault()}
                  src={WebVerde}
                  className="img-fluid"
                  alt=""
                  loading="lazy"
                />
              </div>
              <div className="carousel-item">
                <img
                  onContextMenu={(e) => e.preventDefault()}
                  src={WebAzul}
                  className="img-fluid"
                  alt=""
                  loading="lazy"
                />
              </div>
              <div className="carousel-item">
                <img
                  onContextMenu={(e) => e.preventDefault()}
                  src={WebMorado}
                  className="img-fluid"
                  alt=""
                  loading="lazy"
                />
              </div>
            </div>
          </div>
        </div>

        <div className="col-12 col-lg-6 mx-auto d-flex align-items-center">
          <div
            className="row rounded-3 p-lg-3 p-1 m-3 shadow border-none"
            style={{
              backgroundColor: fondoWebP[currentTextoWebP],
              color: currentTextoWebP > 1 ? "white" : "black",
              transition: "all 1s",
            }}
          >
            <h1 className="h2 fw-bold lh-1 mb-3">
              ¡En Amatica, también somos expertos en el arte de la creación!
            </h1>
            <p className="lead">
              <b className="fw-bold">
                {textoWebP[currentTextoWebP].split(":")[0]}:
              </b>{" "}
              {textoWebP[currentTextoWebP].split(":")[1]}
            </p>
          </div>
        </div>
      </div>

      <div className="col-12 p-3"></div>

      <div className="col-12 py-lg-4"></div>
    </div>
  );
}
export default PrincipalMuestras;
