import { React, useRef, useEffect } from "react";
import { Link } from "react-router-dom";
import "./index.css";
import gsap from "gsap";

import photoExample1 from "../images/landing/slide-mosaico-1.jpg";
import photoExample2 from "../images/landing/slide-mosaico-2.jpg";
import photoExample3 from "../images/landing/slide-mosaico-3.jpg";
import photoExample4 from "../images/landing/slide-mosaico-4.jpg";
import photoExample5 from "../images/landing/slide-mosaico-5.jpg";
import photoExample6 from "../images/landing/slide-mosaico-6.jpg";
import photoExample7 from "../images/landing/slide-mosaico-7.jpg";
import photoExample8 from "../images/landing/slide-mosaico-8.jpg";
import photoExample9 from "../images/landing/slide-mosaico-9.png";
import hoja1 from "../images/landing/flores-rojas.png";

import FloresRojas from "../images/landing/flores-rojas.png";
import LogoBordesNegros from "../images/logo/logo-amatica-bordes-negro.png";
import LogoBordesBlancos from "../images/logo/logo-amatica-bordes-blanco.png";
import PanelAdm from "../images/landing/panel-adm.png";
import ReunionOnline from "../images/landing/reunion.png";
import HombreAdm from "../images/landing/hombre-adm.png";
import MujerDesign from "../images/landing/mujer-app-design.png";
import ConstruyendoWeb from "../images/landing/construyendo-web.png";
import MujerVendedora from "../images/landing/mujer-vendedora.png";
import NegocianteParado from "../images/landing/negociante-parado.png";
import Cientifica from "../images/landing/cientifica.png";
import Constructores from "../images/landing/constructores.png";
import DeveloperMujer from "../images/landing/developer.png";
import ParejaEmpresarial from "../images/landing/pareja-empresarial.png";

import "./PrincipalBodyScripts";
import "./LandingPage.css";
import { drawPoint } from "chart.js/helpers";

function PrincipalBody({ isDay }) {
  const containerRef = useRef(null);

  useEffect(() => {
    window.scrollTo(0, 0); // Esto hará que el scroll vaya al inicio cada vez que se cargue el componente

    const textElements = document.querySelectorAll(".scroll-text");
    const zoomTextElements = document.querySelectorAll(".zoom-text");

    const showOnScroll = () => {
      const triggerBottom = window.innerHeight * 0.9; // 90% del viewport

      textElements.forEach((element) => {
        const elementTop = element.getBoundingClientRect().top;

        if (elementTop < triggerBottom) {
          element.classList.add("show");
        } else {
          element.classList.remove("show");
        }
      });

      zoomTextElements.forEach((element) => {
        const elementTop = element.getBoundingClientRect().top;

        if (elementTop < triggerBottom) {
          element.classList.add("show");
        } else {
          element.classList.remove("show");
        }
      });
    };

    /*
    const hojas = containerRef.current.querySelectorAll(".leaf");
    hojas.forEach((leaf) => animateLeaf(leaf));

    function animateLeaf(leaf) {
      const startX =
        window.innerWidth - Math.random() * (window.innerWidth / 2); // Punto de inicio aleatorio en la mitad superior derecha
      const endX = -100; // Termina fuera de la pantalla a la izquierda
      const duration = 20 + Math.random() * 25; // Caída lenta con duración aleatoria
      const amplitude = 30 + Math.random() * 20; // Oscilación más sutil para evitar saltos bruscos
      const randomDelay = Math.random() * 2; // Reducción del retardo inicial para evitar pausas

      // Animación de caída diagonal suave
      gsap.fromTo(
        leaf,
        {
          x: startX,
          y: -50, // Comienza desde la parte superior
          rotation: Math.random() * 360,
          opacity: 0.9,
        },
        {
          x: endX,
          y: window.innerHeight + 50, // Termina fuera de la pantalla inferior
          rotation: "+=180", // Rotación suave
          duration,
          opacity: 0,
          ease: "power1.inOut", // Suaviza el inicio y fin de la animación
          delay: randomDelay, // Menor retardo inicial para flujo constante
          modifiers: {
            // Movimiento diagonal con oscilación vertical más sutil
            x: gsap.utils.unitize(
              (y) => startX - (startX - endX) * (y / (window.innerHeight + 50))
            ),
            y: gsap.utils.unitize((y) => y + Math.sin(y / 80) * amplitude), // Oscilación más gradual
          },
          onComplete: () => animateLeaf(leaf), // Repetir animación
        }
      );
    }
      */

    window.addEventListener("scroll", showOnScroll);
    showOnScroll(); // Ejecuta la función al cargar para mostrar imágenes visibles al inicio
  }, []);

  return (
    <div
      className={
        isDay === "dia"
          ? "container col-xxl-8 px-4 py-5"
          : "container col-xxl-8 px-4 py-5 text-white"
      }
    >
      <title>Bienvenido a Amática</title>
      <div className="row flex-lg-row-reverse align-items-center g-3">
        <div className="col-12 col-lg-6 scroll-text from-right">
          <img
            unselectable="on"
            src={FloresRojas}
            className="d-block mx-lg-auto mx-auto img-fluid"
            alt=""
            loading="lazy"
          />
        </div>

        <div className="col-12 col-lg-6 scroll-text from-left">
          <h1 className="display-4 lh-1 mb-3 align-middle">
            <img
              unselectable="on"
              src={isDay === "dia" ? LogoBordesNegros : LogoBordesBlancos}
              className="img-fluid"
              loading="lazy"
              alt="logo"
              style={{ height: "50px" }}
            />
            <span className="ms-2 ">Amática</span>
          </h1>
          <h1 className="display-6 lh-1 mb-5 fw-bold" style={{ color: "#ff0000" }}>
            Desarrollo de software
          </h1>
          <p className="h4 fst-italic">
            "Donde existen los problemas, florecen las oportunidades"
          </p>
          <div className="">💻 🤖 🌸</div>
        </div>
      </div>
      <div className="col-12 p-5"></div>

      {/*
      <div
        ref={containerRef}
        style={{
          position: "relative",
          width: "100%",
          height: "800px",
          overflow: "hidden",
        }}
      >
        {Array.from({ length: 15 }).map((_, i) => (
          <img
            key={i}
            src={hoja1}
            className="leaf"
            alt="Hoja"
            style={{
              position: "absolute",
              width: "40px",
              height: "40px",
              opacity: 0.9,
            }}
          />
        ))}
      </div>
 */}
      <div className="row align-items-center g-3">
        <div className="col-12 col-lg-4 scroll-text from-left">
          <img
            unselectable="on"
            src={MujerDesign}
            className="d-block mx-auto img-fluid"
            alt=""
            loading="lazy"
          />
        </div>

        <div className="col-12 col-lg-4 scroll-text from-bottom">
          <h1 className="display-5 fw-bold lh-1 mb-3">
            ¡Bienvenidos a Amática,
          </h1>
          <h1
            className="display-6 fw-bold lh-1 mb-3"
            style={{ color: "#ff8e99" }}
          >
            {" "}
            tu socio de confianza en el mundo del desarrollo de software!
          </h1>
          <p className="lead">
            En Amática, estamos comprometidos en ofrecerte soluciones
            tecnológicas innovadoras y personalizadas que impulsarán tu negocio
            hacia el éxito. Permítenos mostrarte por qué somos la elección
            perfecta para tus necesidades de desarrollo de software.
          </p>
        </div>

        <div className="col-12 col-lg-4 scroll-text from-right">
          <img
            unselectable="on"
            src={HombreAdm}
            className="d-block mx-auto img-fluid"
            alt=""
            loading="lazy"
          />
        </div>
      </div>
      <div className="col-12 p-5"></div>
      {/** SLIDER DE IMAGENES */}
      <div className="">
        <div className="mx-auto text-center row">
          <div className="col-lg-6 col-12 scroll-text from-right">
            <h1
              className="display-6 fw-bold lh-1 mb-3"
              style={{ color: "#ff0061" }}
            >
              Desde startups hasta grandes empresas, hemos impulsado el éxito de
              diversos clientes.
            </h1>
            <p className="lead">
              En Amática, Nuestro software personalizado adapta soluciones a tus
              necesidades específicas. ¡Explora casos de éxito y únete a la
              comunidad Amatica para tu propio viaje digital!.
            </p>
          </div>
          <div className="col-lg-6 col-12 d-flex scroll-text from-right">
            <h1 className="display-5 fw-bold lh-1 mb-3 ms-auto mt-auto">
              ¡Descubre el poder de la transformación digital con Amática!
            </h1>
          </div>
        </div>

        <div className="slider">
          <div className="slide-track">
            <div className="slide slidePrincipal">
              <img
                src={photoExample1}
                className="img-fluid"
                alt=""
                unselectable="on"
                loading="lazy"
              />
            </div>
            <div className="slide slidePrincipal">
              <img
                src={photoExample2}
                className="img-fluid"
                alt=""
                unselectable="on"
                loading="lazy"
              />
            </div>
            <div className="slide slidePrincipal">
              <img
                src={photoExample3}
                className="img-fluid"
                alt=""
                unselectable="on"
                loading="lazy"
              />
            </div>
            <div className="slide slidePrincipal">
              <img
                src={photoExample4}
                className="img-fluid"
                alt=""
                unselectable="on"
                loading="lazy"
              />
            </div>
            <div className="slide slidePrincipal">
              <img
                src={photoExample5}
                className="img-fluid"
                alt=""
                unselectable="on"
                loading="lazy"
              />
            </div>
            <div className="slide slidePrincipal">
              <img
                src={photoExample6}
                className="img-fluid"
                alt=""
                unselectable="on"
                loading="lazy"
              />
            </div>
            <div className="slide slidePrincipal">
              <img
                src={photoExample7}
                className="img-fluid"
                alt=""
                unselectable="on"
                loading="lazy"
              />
            </div>
            <div className="slide slidePrincipal">
              <img
                src={photoExample8}
                className="img-fluid"
                alt=""
                unselectable="on"
                loading="lazy"
              />
            </div>
            <div className="slide slidePrincipal">
              <img
                src={photoExample9}
                className="img-fluid"
                alt=""
                unselectable="on"
                loading="lazy"
              />
            </div>
          </div>
        </div>
      </div>
      <div className="col-12 p-5"></div>
      <div className="row align-items-center g-3">
        {/** bloque azul */}
        <div className="col-12 col-md-6 col-xxl-4 scroll-text from-left">
          <img
            unselectable="on"
            src={ConstruyendoWeb}
            className="d-block mx-lg-auto img-fluid"
            alt=""
            loading="lazy"
          />
        </div>

        <div className="col-12 col-md-6 col-xxl-4 scroll-text from-top">
          <h1
            className="display-6 fw-bold lh-1 mb-3"
            style={{ color: "#08d1ff" }}
          >
            Amática te ayudará a dejar tu huella en el internet
          </h1>
          <img
            unselectable="on"
            src={MujerVendedora}
            className="d-block mx-lg-auto img-fluid"
            alt=""
            loading="lazy"
          />
        </div>

        <div className="col-12 col-md-6 col-xxl-4 bg-dark p-2 redondo text-light scroll-text from-right">
          <p className="lead">
            Deja una huella duradera en el vasto mundo digital con Amática.
            <br />
            Desde sitios web cautivadores hasta aplicaciones innovadoras, te
            damos la plataforma para destacarte y dejar tu marca única en el
            corazón de Internet.
          </p>
          <h1
            className="display-6 fw-bold lh-1 mb-3"
            style={{ color: "#0efff9" }}
          >
            Hacemos la tecnología accesible, intuitiva y atractiva
          </h1>
        </div>

        {/** bloque rojo **/}
        <div className="col-12 col-md-6 col-xxl-4 scroll-text from-left">
          <div className="d-flex">
            <div className="col">
              <img
                unselectable="on"
                src={NegocianteParado}
                className="d-block mx-lg-auto img-fluid"
                alt=""
                loading="lazy"
              />
            </div>
            <div className="col">
              <p className="lead">
                Nuestro servicio de diseño de software se adapta a cualquier
                disciplina.
              </p>
              <h1
                className="display-6 fw-bold lh-1 mb-3"
                style={{ color: "#ff7c7d" }}
              >
                Polivalencia
              </h1>
              <p className="lead">
                Ya sea en finanzas, salud o tecnología, creamos soluciones
                multifacéticas que se integran perfectamente en cualquier
                entorno.
              </p>
            </div>
          </div>
        </div>

        <div className="col-12 col-md-6 col-xxl-4 zoom-text">
          <h1
            className="display-6 fw-bold lh-1 mb-3"
            style={{ color: "#de4864" }}
          >
            Transforma tu gestión de personal con Amática:
            <br />
            Simplificamos la transición de registros físicos a un sistema
            digital eficiente
          </h1>
        </div>

        <div className="col-12 col-md-6 col-xxl-4 scroll-text from-right">
          <div className="d-flex">
            <div className="col">
              <p className="lead">
                Nuestro servicio de diseño de software potencia tus proyectos
                con análisis profundos, modelos predictivos y visualizaciones
                impactantes.
              </p>
              <h1
                className="display-6 fw-bold lh-1 mb-3"
                style={{ color: "#9f3155" }}
              >
                De datos a decisiones estratégicas
              </h1>
            </div>
            <div className="col">
              <img
                unselectable="on"
                src={Cientifica}
                className="d-block mx-lg-auto img-fluid"
                alt=""
                loading="lazy"
              />
            </div>
          </div>
        </div>

        {/** bloque morado */}
        <div className="col-12 col-md-6 col-xxl-4 bg-dark p-2 redondo text-light scroll-text from-left">
          <p className="lead">
            La adaptabilidad es nuestra fortaleza, descubre soluciones flexibles
            que evolucionan contigo.
          </p>
          <h1
            className="display-6 fw-bold lh-1 mb-3"
            style={{ color: "#a01ba7" }}
          >
            Amática: donde la adaptabilidad impulsa la innovación digital
          </h1>
          <p className="lead">
            Ya sea en diseño web, desarrollo de software o ciencia de datos, nos
            destacamos en adaptarnos a tus necesidades cambiantes.
          </p>
        </div>

        <div className="col-12 col-md-6 col-xxl-4 scroll-text from-bottom">
          <img
            unselectable="on"
            src={Constructores}
            className="d-block mx-lg-auto img-fluid"
            alt=""
            loading="lazy"
          />
          <h1
            className="display-6 fw-bold lh-1 mb-3"
            style={{ color: "#7045ff" }}
          >
            Seguridad
          </h1>
          <p className="lead">
            Los registros en papel son susceptibles a pérdidas y daños. Con
            Amática, tus datos están seguros en un entorno digital protegido,
            con medidas de seguridad avanzadas para garantizar la
            confidencialidad.
          </p>
        </div>

        <div className="d-none d-xxl-block col-xxl-4 scroll-text from-right">
          <img
            unselectable="on"
            src={DeveloperMujer}
            className="d-block mx-lg-auto img-fluid"
            alt=""
            loading="lazy"
          />
        </div>
      </div>
      <div className="col-12 p-5"></div>
      {/** LINK CASOS DE USO */}
      <div className="row flex-lg-row-reverse align-items-center g-3 py-3">
        <div className="col-12 col-lg-6 scroll-text from-right">
          <img
            unselectable="on"
            src={PanelAdm}
            className="d-block mx-lg-auto mx-auto img-fluid"
            alt=""
            loading="lazy"
          />
        </div>

        <div className="col-12 col-lg-6 scroll-text from-left">
          <h1 className="display-5 fw-bold lh-1 mb-3">
            ¿Curiosidad sobre lo que Amática puede lograr?
          </h1>
          <h1 className="display-6 fw-bold lh-1 mb-3 text-warning">
            Explora nuestros ejemplos y casos de uso en el sitio.
          </h1>
          <p className="lead">
            Descubre cómo hemos transformado ideas en soluciones digitales.
            Inspírate y contáctanos para empezar tu propio viaje digital. Tu
            historia única está a solo un clic de distancia.
          </p>
          <div className="d-grid gap-2 d-md-flex justify-content-md-center mt-4">
            <button
              type="button"
              className="btn btn-outline-warning btn-lg px-4 me-md-2"
              style={{ borderWidth: "3px" }}
            >
              <Link to="/casosDeUso" className="nav-link d-flex">
                <div className="my-auto">
                  <i className="bi bi-code-square me-2"></i>
                  <span>Casos de Uso</span>
                </div>
              </Link>
            </button>
          </div>
        </div>
      </div>
      <div className="col-12 p-5"></div>
      {/** LINK CONTACTO */}
      <div className="row align-items-center g-3">
        <div className="col-12 col-lg-6 scroll-text from-right">
          <img
            unselectable="on"
            src={ReunionOnline}
            className="d-block mx-lg-auto mx-auto img-fluid"
            alt=""
            loading="lazy"
          />
        </div>

        <div className="col-12 col-lg-6  scroll-text from-left">
          <h1 className="display-5 fw-bold lh-1 mb-3">
            ¿Listo para dar el siguiente paso en tu viaje digital?{" "}
          </h1>
          <h1 className="display-6 fw-bold lh-1 mb-3 text-success">
            Estamos aquí para convertir tus ideas en realidad.
          </h1>
          <p className="lead">
            Conecta con nosotros hoy y descubre cómo nuestras soluciones
            personalizadas pueden potenciar tu presencia en línea.
            <br />
            Juntos, crearemos una historia digital que destaque. ¡Contáctanos y
            comencemos tu transformación!
          </p>
          <div className="d-grid gap-2 d-md-flex justify-content-md-center mt-4">
            <button
              type="button"
              className="btn btn-outline-success btn-lg px-4 me-md-2"
              style={{ borderWidth: "3px" }}
            >
              <Link to="/contacto" className="nav-link">
                <div className="my-auto">
                  <i className="bi bi-envelope me-2"></i>
                  <span>Contactar</span>
                </div>
              </Link>
            </button>
          </div>
        </div>
      </div>
      <div className="col-12 p-5"></div>
      {/** AVISO FINAL DEL LANDING */}
      <div className="row align-items-center g-3 py-3">
        <div className="col-12 col-lg-6  scroll-text from-left">
          <h1
            className="display-6 fw-bold lh-1 mb-3"
            style={{ color: "#c6456f" }}
          >
            Estamos emocionados de ser tu socio en la transformación digital
          </h1>
          <p className="lead">
            Gracias por explorar el mundo digital con Amática. Cada proyecto es
            un viaje único, y queremos ser parte de tu historia.
            <br />
            Hasta la próxima etapa, donde juntos comenzaremos a construir
            experiencias que perduren en el tiempo.
          </p>
          <h1 className="display-5 fw-bold lh-1 mb-3">
            No te quedes con la duda,
            <Link to="/contacto" className="link-primary">
              ¡Hablemos!
            </Link>
          </h1>
        </div>

        <div className="col-12 col-lg-6  scroll-text from-right">
          <img
            unselectable="on"
            src={ParejaEmpresarial}
            className="d-block mx-lg-auto mx-auto img-fluid"
            alt=""
            loading="lazy"
          />
        </div>
      </div>
      <div className="col-12 p-3"></div>
    </div>
  );
}

export default PrincipalBody;
