import { React, useEffect } from "react";
import { Link } from "react-router-dom";
import "../../index.css";
import logoBordesBlancos from "../../images/logo/logo-amatica-bordes-blanco.png";
import tiendaFlores from "../../images/servicios/tienda-flores.png";
import cafeTorta from "../../images/servicios/cafe-torta.png";
import manosDocumentos from "../../images/servicios/manos-documentos.png";
import paginaWeb from "../../images/servicios/pagina-web.png";
import appMovil from "../../images/servicios/app-movil.png";

function PrincipalServicios({ isDay }) {
  useEffect(() => {
    window.scrollTo(0, 0); // Esto hará que el scroll vaya al inicio cada vez que se cargue el componente

    const textElements = document.querySelectorAll(".scroll-text");

    const showOnScroll = () => {
      const triggerBottom = window.innerHeight * 0.9; // 90% del viewport

      textElements.forEach((element) => {
        const elementTop = element.getBoundingClientRect().top;

        if (elementTop < triggerBottom) {
          element.classList.add("show");
        } else {
          element.classList.remove("show");
        }
      });
    };

    window.addEventListener("scroll", showOnScroll);
    showOnScroll(); // Ejecuta la función al cargar para mostrar imágenes visibles al inicio
  }, []);

  return (
    <div
      className={
        isDay === "dia"
          ? "container col-xxl-8 py-5 row mx-auto"
          : "container col-xxl-8 col-12 py-5 row mx-auto text-white"
      }
    >
      <div className="col-12 py-lg-4"></div>
      <title>Servicios de Amática</title>

      <div className="col-12 col-lg-4 row mx-auto">
        <div className="col order-lg-2 col-12 col-md-6 col-lg-12  scroll-text from-left">
          <img
            onContextMenu={(e) => e.preventDefault()}
            src={tiendaFlores}
            className="img-fluid"
            alt=""
            width="700"
            height="500"
            loading="lazy"
          />
        </div>

        <div className="col order-lg-2 col-12 col-md-6 col-lg-12  scroll-text from-bottom">
          <h1 className="display-6 fw-bold lh-1 mb-3">
            Llevemos tu negocio a la web
          </h1>
          <p className="lead">
            Ya sea con un simple aviso con datos de contacto hasta sistemas de
            compra venta de articulos personalizados, ¡consulta por ello!
          </p>
        </div>
      </div>

      <div className="col-12 col-lg-4 row mx-auto">
        <div className="col order-lg-2 col-12 col-md-6 col-lg-12  scroll-text from-bottom">
          <img
            onContextMenu={(e) => e.preventDefault()}
            src={cafeTorta}
            className="img-fluid"
            alt=""
            loading="lazy"
          />
        </div>

        <div className="col order-lg-1 col-12 col-md-6 col-lg-12  scroll-text from-top">
          <h1 className="display-6 fw-bold lh-1 mb-3">
            Renovar o agregar funcionalidades al sitio
          </h1>
          <p className="lead">
            Si ya tienes software personal y quieres modificar o actualizar
            ciertas cosas, ¡tambien podemos ayudarte!
          </p>
        </div>
      </div>

      <div className="col-12 col-lg-4 row mx-auto">
        <div className="col order-lg-2 col-12 col-md-6 col-lg-12  scroll-text from-right">
          <img
            onContextMenu={(e) => e.preventDefault()}
            src={manosDocumentos}
            className="img-fluid"
            alt=""
            width="700"
            height="500"
            loading="lazy"
          />
        </div>

        <div className="col order-lg-2 col-12 col-md-6 col-lg-12  scroll-text from-bottom">
          <h1 className="display-6 fw-bold lh-1 mb-3">
            Administracion de recursos informáticos empresariales
          </h1>
          <p className="lead">
            Organizar los datos de clientes, finanzas, archivos y demas recursos
            digitales con reglas complejas en sistemas informáticos, ¡podemos
            hacerlo!
          </p>
        </div>
      </div>

      <div className="col-12 py-4"></div>

      {/* LOGO DE LA EMPRESA */}
      <div className="p-4 text-center mx-auto text-white redondo bg-gradient col-lg-10 bg-dark  scroll-text from-bottom">
        <img
          onContextMenu={(e) => e.preventDefault()}
          alt=""
          className="d-block mx-auto mb-4 col-4 col-sm-3 col-md-2 col-lg-1"
          src={logoBordesBlancos}
        />
        <h1 className="display-5 fw-bold col-lg-8 mx-auto">
          Soluciones de software para todo tipo de empresas
        </h1>
        <div className="col-lg-12 mx-auto">
          <p className="lead mt-4">
            <b>Amática</b> ofrece soluciones de desarrollo de software para
            PYMES multipropositos y para administracion empresarial de recursos,
            así como soluciones rápidas de ente comercial o comunicativo, una
            estrategia integral para abordar diversas necesidades solicitadas en
            el mercado. Ofrecemos los siguientes tipos de recursos informáticos:
          </p>
        </div>
      </div>

      <div className="col-12 py-4"></div>

      {/* SOLUCIONES WEB */}
      <div className="row col-12 align-items-center pb-5 mx-auto">
        <div className="col-12 col-lg-6 mx-auto scroll-text from-left">
          <img
            onContextMenu={(e) => e.preventDefault()}
            alt=""
            src={paginaWeb}
            className="d-block mx-lg-auto img-fluid"
            width="700"
            height="500"
            loading="lazy"
          />
        </div>

        <div className="col-12 col-lg-6">
          <h1 className="display-6 fw-bold lh-1 mb-3">
            Desarrollo de soluciones web
          </h1>
          <p className="lead">
            Actualmente la humanidad vive a diario del internet, los directorios
            telefonicos quedaron en el pasado y la nueva forma de encontrar
            servicios es a travez de internet, Amática puede ayudarte a
            posicionarte en la web para anunciar tu negocio.
          </p>

          <div className="col-12 row text-start mt-4 scroll-text from-right">
            <div className="col-auto">
              <div className="p-3 bg-danger rounded  bg-gradient">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="32"
                  height="32"
                  fill="currentColor"
                  className="bi bi-geo-alt-fill text-white"
                  viewBox="0 0 16 16"
                >
                  <path d="M8 16s6-5.686 6-10A6 6 0 0 0 2 6c0 4.314 6 10 6 10zm0-7a3 3 0 1 1 0-6 3 3 0 0 1 0 6z" />
                </svg>
              </div>
            </div>

            <div className="col">
              Un sitio web profesional es tu tarjeta de presentación en el mundo
              digital. Con él, podrás llegar a un público más amplio, mostrar
              tus productos o servicios las 24 horas del día, los 7 días de la
              semana, y aumentar tus oportunidades de crecimiento y éxito.{" "}
            </div>
          </div>

          <div className="col-12 row text-start mt-4 scroll-text from-right">
            <div className="col-auto">
              <div className="p-3 bg-danger rounded  bg-gradient">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="32"
                  height="32"
                  fill="currentColor"
                  className="bi bi-palette-fill text-white"
                  viewBox="0 0 16 16"
                >
                  <path d="M12.433 10.07C14.133 10.585 16 11.15 16 8a8 8 0 1 0-8 8c1.996 0 1.826-1.504 1.649-3.08-.124-1.101-.252-2.237.351-2.92.465-.527 1.42-.237 2.433.07zM8 5a1.5 1.5 0 1 1 0-3 1.5 1.5 0 0 1 0 3zm4.5 3a1.5 1.5 0 1 1 0-3 1.5 1.5 0 0 1 0 3zM5 6.5a1.5 1.5 0 1 1-3 0 1.5 1.5 0 0 1 3 0zm.5 6.5a1.5 1.5 0 1 1 0-3 1.5 1.5 0 0 1 0 3z" />
                </svg>
              </div>
            </div>

            <div className="col">
              En Amática, entendemos que cada negocio es único. Por eso,
              ofrecemos soluciones personalizadas que se adaptan a tus
              necesidades específicas. Ya sea que necesites una tienda en línea,
              un blog o un sitio corporativo, tenemos la solución perfecta para
              ti.{" "}
            </div>
          </div>

          <div className="col-12 row text-start mt-4 scroll-text from-right">
            <div className="col-auto">
              <div className="p-3 bg-danger rounded  bg-gradient">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="32"
                  height="32"
                  fill="currentColor"
                  className="bi bi-bag-heart-fill text-white"
                  viewBox="0 0 16 16"
                >
                  <path d="M11.5 4v-.5a3.5 3.5 0 1 0-7 0V4H1v10a2 2 0 0 0 2 2h10a2 2 0 0 0 2-2V4h-3.5ZM8 1a2.5 2.5 0 0 1 2.5 2.5V4h-5v-.5A2.5 2.5 0 0 1 8 1Zm0 6.993c1.664-1.711 5.825 1.283 0 5.132-5.825-3.85-1.664-6.843 0-5.132Z" />
                </svg>
              </div>
            </div>

            <div className="col">
              Tanto la funcionalidad como el diseño son prioriedad para un sitio
              web atractivo y buena curva de aprendizaje que cautivará a tus
              visitantes y los convertirá en clientes satisfechos.{" "}
            </div>
          </div>

          <div className="col-12 row text-start mt-4 scroll-text from-right">
            <div className="col-auto">
              <div className="p-3 bg-danger rounded  bg-gradient">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="32"
                  height="32"
                  fill="currentColor"
                  className="bi bi-gear-fill text-white"
                  viewBox="0 0 16 16"
                >
                  <path d="M9.405 1.05c-.413-1.4-2.397-1.4-2.81 0l-.1.34a1.464 1.464 0 0 1-2.105.872l-.31-.17c-1.283-.698-2.686.705-1.987 1.987l.169.311c.446.82.023 1.841-.872 2.105l-.34.1c-1.4.413-1.4 2.397 0 2.81l.34.1a1.464 1.464 0 0 1 .872 2.105l-.17.31c-.698 1.283.705 2.686 1.987 1.987l.311-.169a1.464 1.464 0 0 1 2.105.872l.1.34c.413 1.4 2.397 1.4 2.81 0l.1-.34a1.464 1.464 0 0 1 2.105-.872l.31.17c1.283.698 2.686-.705 1.987-1.987l-.169-.311a1.464 1.464 0 0 1 .872-2.105l.34-.1c1.4-.413 1.4-2.397 0-2.81l-.34-.1a1.464 1.464 0 0 1-.872-2.105l.17-.31c.698-1.283-.705-2.686-1.987-1.987l-.311.169a1.464 1.464 0 0 1-2.105-.872l-.1-.34zM8 10.93a2.929 2.929 0 1 1 0-5.86 2.929 2.929 0 0 1 0 5.858z" />
                </svg>
              </div>
            </div>

            <div className="col">
              No te preocupes por la gestión de recursos de tu sitio web. Te
              proporcionaremos las herramientas y la capacitación necesaria para
              que conozcas la forma de mantener tu sitio eficiente de forma
              sencilla.{" "}
            </div>
          </div>
        </div>

        <div className="col-lg-9 col-12 mx-auto mt-4">
          <span className="lead">
            Las soluciones web son la cúspide de la conectividad moderna, no
            dejes pasar más tiempo sin una presencia en línea efectiva.
            ¡Contáctanos hoy mismo y descubre cómo podemos ayudarte a despegar
            en el mundo digital!
          </span>
        </div>
      </div>

      <div className="col-12 py-2"></div>

      {/* APLICACIONES MOVILES */}
      <div className="row col-12 align-items-center pb-3 mx-auto">
        <div className="col-12 col-lg-6 mx-auto">
          <img
            onContextMenu={(e) => e.preventDefault()}
            alt=""
            src={appMovil}
            className="d-block mx-lg-auto img-fluid scroll-text from-left"
            width="700"
            height="500"
            loading="lazy"
          />
        </div>

        <div className="col-12 col-lg-6">
          <h1 className="display-6 fw-bold lh-1 mb-3">
            Desarrollo de aplicaciones moviles
          </h1>
          <p className="lead">
            En Amática, estamos listos para convertir tu visión en una
            aplicación multiplataforma. Si tienes una idea brillante o una
            necesidad en mente, ¡nosotros te ayudamos a hacerla realidad!{" "}
          </p>

          <div className="col-12 row text-start mt-4 scroll-text from-right">
            <div className="col-auto">
              <div className="p-3 bg-danger rounded  bg-gradient">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="32"
                  height="32"
                  fill="currentColor"
                  className="bi bi-android2 text-white"
                  viewBox="0 0 16 16"
                >
                  <path d="m10.213 1.471.691-1.26c.046-.083.03-.147-.048-.192-.085-.038-.15-.019-.195.058l-.7 1.27A4.832 4.832 0 0 0 8.005.941c-.688 0-1.34.135-1.956.404l-.7-1.27C5.303 0 5.239-.018 5.154.02c-.078.046-.094.11-.049.193l.691 1.259a4.25 4.25 0 0 0-1.673 1.476A3.697 3.697 0 0 0 3.5 5.02h9c0-.75-.208-1.44-.623-2.072a4.266 4.266 0 0 0-1.664-1.476ZM6.22 3.303a.367.367 0 0 1-.267.11.35.35 0 0 1-.263-.11.366.366 0 0 1-.107-.264.37.37 0 0 1 .107-.265.351.351 0 0 1 .263-.11c.103 0 .193.037.267.11a.36.36 0 0 1 .112.265.36.36 0 0 1-.112.264Zm4.101 0a.351.351 0 0 1-.262.11.366.366 0 0 1-.268-.11.358.358 0 0 1-.112-.264c0-.103.037-.191.112-.265a.367.367 0 0 1 .268-.11c.104 0 .19.037.262.11a.367.367 0 0 1 .107.265c0 .102-.035.19-.107.264ZM3.5 11.77c0 .294.104.544.311.75.208.204.46.307.76.307h.758l.01 2.182c0 .276.097.51.292.703a.961.961 0 0 0 .7.288.973.973 0 0 0 .71-.288.95.95 0 0 0 .292-.703v-2.182h1.343v2.182c0 .276.097.51.292.703a.972.972 0 0 0 .71.288.973.973 0 0 0 .71-.288.95.95 0 0 0 .292-.703v-2.182h.76c.291 0 .54-.103.749-.308.207-.205.311-.455.311-.75V5.365h-9v6.404Zm10.495-6.587a.983.983 0 0 0-.702.278.91.91 0 0 0-.293.685v4.063c0 .271.098.501.293.69a.97.97 0 0 0 .702.284c.28 0 .517-.095.712-.284a.924.924 0 0 0 .293-.69V6.146a.91.91 0 0 0-.293-.685.995.995 0 0 0-.712-.278Zm-12.702.283a.985.985 0 0 1 .712-.283c.273 0 .507.094.702.283a.913.913 0 0 1 .293.68v4.063a.932.932 0 0 1-.288.69.97.97 0 0 1-.707.284.986.986 0 0 1-.712-.284.924.924 0 0 1-.293-.69V6.146c0-.264.098-.491.293-.68Z" />
                </svg>
              </div>
            </div>

            <div className="col">
              Una interfaz de usuario elegante y una experiencia de usuario
              excepcional son cruciales para el éxito de cualquier aplicación,
              nosotros diseñamos aplicaciones que cautivan a tus usuarios desde
              el primer toque.{" "}
            </div>
          </div>

          <div className="col-12 row text-start mt-4 scroll-text from-right">
            <div className="col-auto">
              <div className="p-3 bg-danger rounded  bg-gradient">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="32"
                  height="32"
                  fill="currentColor"
                  className="bi bi-apple text-white"
                  viewBox="0 0 16 16"
                >
                  <path d="M11.182.008C11.148-.03 9.923.023 8.857 1.18c-1.066 1.156-.902 2.482-.878 2.516.024.034 1.52.087 2.475-1.258.955-1.345.762-2.391.728-2.43Zm3.314 11.733c-.048-.096-2.325-1.234-2.113-3.422.212-2.189 1.675-2.789 1.698-2.854.023-.065-.597-.79-1.254-1.157a3.692 3.692 0 0 0-1.563-.434c-.108-.003-.483-.095-1.254.116-.508.139-1.653.589-1.968.607-.316.018-1.256-.522-2.267-.665-.647-.125-1.333.131-1.824.328-.49.196-1.422.754-2.074 2.237-.652 1.482-.311 3.83-.067 4.56.244.729.625 1.924 1.273 2.796.576.984 1.34 1.667 1.659 1.899.319.232 1.219.386 1.843.067.502-.308 1.408-.485 1.766-.472.357.013 1.061.154 1.782.539.571.197 1.111.115 1.652-.105.541-.221 1.324-1.059 2.238-2.758.347-.79.505-1.217.473-1.282Z" />
                </svg>
              </div>
            </div>

            <div className="col">
              Todas las aplicaciones desarrolladas por nosotros son
              multiplataforma, esto quiere decir que funcionaran tanto en
              plataformas Android como IOS.{" "}
            </div>
          </div>

          <div className="col-12 row text-start mt-4 scroll-text from-right">
            <div className="col-auto">
              <div className="p-3 bg-danger rounded  bg-gradient">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="32"
                  height="32"
                  fill="currentColor"
                  className="bi bi-hourglass-bottom text-white"
                  viewBox="0 0 16 16"
                >
                  <path d="M2 1.5a.5.5 0 0 1 .5-.5h11a.5.5 0 0 1 0 1h-1v1a4.5 4.5 0 0 1-2.557 4.06c-.29.139-.443.377-.443.59v.7c0 .213.154.451.443.59A4.5 4.5 0 0 1 12.5 13v1h1a.5.5 0 0 1 0 1h-11a.5.5 0 1 1 0-1h1v-1a4.5 4.5 0 0 1 2.557-4.06c.29-.139.443-.377.443-.59v-.7c0-.213-.154-.451-.443-.59A4.5 4.5 0 0 1 3.5 3V2h-1a.5.5 0 0 1-.5-.5zm2.5.5v1a3.5 3.5 0 0 0 1.989 3.158c.533.256 1.011.791 1.011 1.491v.702s.18.149.5.149.5-.15.5-.15v-.7c0-.701.478-1.236 1.011-1.492A3.5 3.5 0 0 0 11.5 3V2h-7z" />
                </svg>
              </div>
            </div>

            <div className="col">
              Las aplicaciones móviles se inician rápidamente con un solo toque
              en el ícono de la pantalla de inicio y con la posibilidad de no
              depender de los datos moviles. Esto ahorra tiempo a los usuarios y
              facilita el acceso constante a tu plataforma.{" "}
            </div>
          </div>

          <div className="col-12 row text-start mt-4 scroll-text from-right">
            <div className="col-auto">
              <div className="p-3 bg-danger rounded  bg-gradient">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="32"
                  height="32"
                  fill="currentColor"
                  className="bi bi-bell-fill text-white"
                  viewBox="0 0 16 16"
                >
                  <path d="M8 16a2 2 0 0 0 2-2H6a2 2 0 0 0 2 2zm.995-14.901a1 1 0 1 0-1.99 0A5.002 5.002 0 0 0 3 6c0 1.098-.5 6-2 7h14c-1.5-1-2-5.902-2-7 0-2.42-1.72-4.44-4.005-4.901z" />
                </svg>
              </div>
            </div>

            <div className="col">
              Las notificaciones push son una herramienta poderosa para la
              retención de usuarios y el compromiso. Puedes enviar
              recordatorios, noticias importantes o promociones directamente a
              la pantalla de inicio de los usuarios, lo que aumenta la
              visibilidad de tu marca.{" "}
            </div>
          </div>
        </div>

        <div className="col-lg-9 col-12 mx-auto mt-4">
          <span className="lead">
            Entre muchas otras caracteristicas personalizables y ventajas, no
            dejes que tu idea se quede en el papel. ¡Contáctanos hoy mismo y
            comencemos a convertirla en una aplicación móvil todo terreno!
          </span>
        </div>
      </div>

      <div className="col-12 py-3"></div>

      {/* POR QUE ELEGIR AMATICA */}
      <div className="p-4 text-center mx-auto bg-primary text-white redondo bg-gradient col-lg-10">
        <h1 className="display-6 fw-bold col-lg-8 mx-auto">
          ¿Porque elegir a Amática?
        </h1>
        <div className="col-lg-12 mx-auto">
          <p className="lead mt-4 col-12">
            Podemos proporcionar una amplia gama de servicios y soluciones que
            benefician a las pequeñas empresas de diversas maneras. Estos son
            algunos de los servicios que proporcionar Amática:
          </p>

          <div className="row col-12 mx-auto">
            <div className="col-lg-4 col-12 col-md-6">
              <div className="bg-white text-dark redondo row mx-1 my-3  scroll-text from-left">
                <div className="col-2 text-end py-2">
                  <i className="bi bi-check-square-fill"></i>
                </div>
                <div className="col-10 text-start py-2">
                  <b>Desarrollo de Software Personalizado:</b> Una empresa de
                  software puede diseñar y desarrollar aplicaciones
                  personalizadas que se adapten específicamente a las
                  necesidades de una PYME. Esto puede incluir sistemas de
                  gestión empresarial, aplicaciones móviles, herramientas de
                  automatización de procesos y más.
                </div>
              </div>
            </div>
            <div className="col-lg-4 col-12 col-md-6">
              <div className="bg-white text-dark redondo row mx-1 my-3  scroll-text from-top">
                <div className="col-2 text-end py-2">
                  <i className="bi bi-check-square-fill"></i>
                </div>
                <div className="col-10 text-start py-2">
                  <b>Optimización de Procesos:</b> A través de la automatización
                  y la integración de sistemas, una empresa de software puede
                  ayudar a las empresas a optimizar sus procesos comerciales.
                  Esto puede ahorrar tiempo y recursos, aumentar la eficiencia y
                  reducir los errores humanos.
                </div>
              </div>
            </div>
            <div className="col-lg-4 col-12 col-md-6">
              <div className="bg-white text-dark redondo row mx-1 my-3  scroll-text from-right">
                <div className="col-2 text-end py-2">
                  <i className="bi bi-check-square-fill"></i>
                </div>
                <div className="col-10 text-start py-2">
                  <b>Presencia en Línea:</b> Ayudar a las empresas a establecer
                  y mejorar su presencia en línea a través de la creación de
                  sitios web atractivos y funcionales, tiendas en línea, blogs y
                  perfiles en redes sociales. Esto es esencial para llegar a un
                  público más amplio y competir en el entorno digital actual.
                </div>
              </div>
            </div>
            <div className="col-lg-4 col-12 col-md-6">
              <div className="bg-white text-dark redondo row mx-1 my-3  scroll-text from-left">
                <div className="col-2 text-end py-2">
                  <i className="bi bi-check-square-fill"></i>
                </div>
                <div className="col-10 text-start py-2">
                  <b>Seguridad Cibernética:</b> Proporcionar medidas de
                  seguridad cibernética para proteger los datos y la información
                  confidencial ingresada en los sistemas proveidos por nosotros,
                  contra amenazas en línea, como hackers y malware.
                </div>
              </div>
            </div>
            <div className="col-lg-4 col-12 col-md-6">
              <div className="bg-white text-dark redondo row mx-1 my-3  scroll-text from-bottom">
                <div className="col-2 text-end py-2">
                  <i className="bi bi-check-square-fill"></i>
                </div>
                <div className="col-10 text-start py-2">
                  <b>Personalización de Software de Terceros:</b> Adaptar y
                  personalizar software de terceros, como sistemas de gestión de
                  relaciones con el cliente o software de contabilidad, para que
                  se ajuste a las necesidades actuales de su empresa, haga la
                  consulta.
                </div>
              </div>
            </div>
            <div className="col-lg-4 col-12 col-md-6">
              <div className="bg-white text-dark redondo row mx-1 my-3  scroll-text from-right">
                <div className="col-2 text-end py-2">
                  <i className="bi bi-check-square-fill"></i>
                </div>
                <div className="col-10 text-start py-2">
                  <b>Asesoramiento Tecnológico:</b> Ofrecer orientación
                  estratégica sobre las tendencias tecnológicas y las mejores
                  prácticas para ayudar a las empresas a tomar decisiones
                  informadas sobre inversiones en tecnología.
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="col-12 row my-5 ">
        <div className="col-lg-9 col-12 mx-auto ">
          <span className="lead">
            El software personalizado que ofrece Amática puede ser un socio
            valioso para las pequeñas empresas al proporcionar soluciones
            tecnológicas que mejoran la eficiencia, la productividad y la
            competitividad en un mundo empresarial cada vez más digitalizado.
            ¡Animate a hacer tus consultas!{" "}
          </span>
        </div>
        <div className="d-grid gap-2 d-md-flex justify-content-md-center mt-4">
          <button type="button" className="btn btn-primary btn-lg px-4 me-md-2">
            <Link to="/contacto" className="nav-link">
              Contactar
            </Link>
          </button>
        </div>
      </div>
    </div>
  );
}
export default PrincipalServicios;
