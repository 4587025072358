import axios from 'axios';

//axios.defaults.baseURL = "http://localhost:5000/api";
axios.defaults.baseURL = "https://api.amatica.cl/api";
//axios.defaults.baseURL = "https://localhost:5001/api";
axios.defaults.headers.common['Access-Control-Allow-Origin'] = '*';
axios.defaults.headers.common['Access-Control-Allow-Methods'] = 'GET,PUT,POST,DELETE';
axios.defaults.headers.common['Access-Control-Allow-Headers'] = 'Content-Type';

// respuestas para verbos http con axios
const requestGenerico = {
    get: (url) => axios.get(url),
    post: (url, body) => axios.post(url, body),
    put: (url, body) => axios.put(url, body),
    delete: (url) => axios.delete(url)
};

export default requestGenerico;